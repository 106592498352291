import {isDevelopmentEnvironment} from 'common/isTestEnvironment';
import {tocaLocations, tocaLocationsWithoutWorkingMboSiteId} from 'constants/locations';

export const locations = [...tocaLocations, ...tocaLocationsWithoutWorkingMboSiteId].filter(
  (loc) =>
    loc.referralSupported && ((!isDevelopmentEnvironment && !loc.devSite) || (isDevelopmentEnvironment && loc.devSite))
);

export const HUBSPOT_USER_FIRST_NAME_PARAM = 'firstname';
export const HUBSPOT_USER_LAST_NAME_PARAM = 'lastname';
export const HUBSPOT_USER_EMAIL_PARAM = 'email';
export const HUBSPOT_USER_PHONE_PARAM = 'phone';
export const HUBSPOT_LOCATION_PARAM = 'locations__drop_down_';
