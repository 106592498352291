import {useSearchParams} from 'react-router-dom';
import {ChangeEventHandler, useCallback, useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {TocaLogo} from 'components/Forms/FkoLogos/TocaLogo';
import {SelectOptions, StandardSelect} from 'components/Select/StandardSelect/StandardSelect';
import {getLocationByHubspotName, TocaLocation} from 'constants/locations';
import {ActivityLinks} from './ActivityLinks';
import {useSportContext} from 'context/SportContext';
import {locations} from './utils';

export enum TocaProgramsLandingSource {
  REFERRAL = 'referrals',
  GRASS_ROOTS = 'grassroots',
}

const validSources: TocaProgramsLandingSource[] = [
  TocaProgramsLandingSource.REFERRAL,
  TocaProgramsLandingSource.GRASS_ROOTS,
];

interface TocaProgramsLandingProps {
  source?: TocaProgramsLandingSource;
}

const createLocationOptions = (locations: TocaLocation[]) => [
  {value: '', displayValue: 'Select location', disabled: true},
  ...locations.map((location) => ({
    value: location.siteId,
    displayValue: location.name,
  })),
];

export const locationOptions = createLocationOptions(locations);

const TocaProgramsLanding = ({source}: TocaProgramsLandingProps) => {
  const {setSport} = useSportContext();
  const [selectedLocation, setSelectedLocation] = useState<TocaLocation | null>(null);
  const [searchParams] = useSearchParams();

  const onSelectLocation: ChangeEventHandler<SelectOptions> = useCallback((e) => {
    const selected = locations.find((location) => location.siteId === e.currentTarget.value);
    setSelectedLocation(selected || null);
  }, []);

  // Get source from search params or use the prop
  const sourceFromParams = searchParams.get('toca_source');
  const validSource =
    source ??
    (sourceFromParams && validSources.includes(sourceFromParams as TocaProgramsLandingSource)
      ? sourceFromParams
      : undefined);

  useEffect(() => {
    setSport({sport: 'TOCA'});
  }, [setSport]);

  // Determine the default selected location on mount
  useEffect(() => {
    const locationHubspotName = searchParams.get('locations__drop_down_');
    const locationId = searchParams.get('locationId');
    if (locationHubspotName) {
      setSelectedLocation(getLocationByHubspotName(locationHubspotName, locations) || null);
    } else if (locationId) {
      const selectedLocation = locations.find((location) => location.siteId === locationId);
      setSelectedLocation(selectedLocation || null);
    }
  }, [searchParams]);

  // Define title and content based on the source
  let pageTitle = 'Toca Programs';
  let subHeading = (
    <p className="mt-6">
      When you join, you&apos;ll also receive{' '}
      <span className="font-semibold">15% off your first month of membership</span> so you can experience all
      TOCA&apos;s benefits firsthand.
    </p>
  );

  let showLocationSelect = true;
  let showSelectedLocationText = false;

  if (validSource === TocaProgramsLandingSource.REFERRAL) {
    pageTitle = 'Referral';
  } else if (validSource === TocaProgramsLandingSource.GRASS_ROOTS) {
    pageTitle = 'Grassroots';
    subHeading = <></>;

    if (selectedLocation) {
      showLocationSelect = false;
      showSelectedLocationText = true;
    }
  }

  return (
    <div className="flex flex-col items-center exceed-classic-padding pl-6 pr-6">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <TocaLogo />
      <h1 className="text-[26px] text-center mt-2 mb-4">Welcome to TOCA!</h1>

      {!selectedLocation ? (
        <div className="font-light text-base text-center mb-6">
          <p className="font-semibold">Select your center to sign up for a FREE experience.</p>
          {subHeading}
        </div>
      ) : showSelectedLocationText ? (
        <p className="font-semibold text-base text-center">Your center: {selectedLocation?.name}</p>
      ) : null}

      {showLocationSelect && (
        <StandardSelect
          id="location-select"
          options={locationOptions}
          onChange={onSelectLocation}
          variant="normal"
          value={selectedLocation?.siteId || locationOptions[0].value}
        />
      )}

      {selectedLocation && <ActivityLinks location={selectedLocation} source={validSource} />}
    </div>
  );
};

TocaProgramsLanding.displayName = 'TocaProgramsLanding';

export default TocaProgramsLanding;
