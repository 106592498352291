import {useEffect} from 'react';
import styles from './RegistrationScheduling.module.scss';
import {useNavigate} from 'react-router-dom';
import {SchedulingForm, Session} from './SchedulingForm';
import {getUrlSearchParam} from '../../utils/utils';
import {logAnalyticsEvent} from '../../common/analytics-events';
import {Helmet} from 'react-helmet';
import {
  addFkoAppointment,
  removeFkoAppointment,
  setFkoCurrentPage,
  updateFkoPlayer,
} from '../../redux/slices/fkoFormData';
import {useAppDispatch, useAppSelector} from '../../redux/reduxUtils';
import {useGetSport} from 'common/useGetSport';
import {FKOPages} from 'common/fko-utils';

export const RegistrationScheduling = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {sessionTypeName, isDbat, isPlayerAssessment, basePath, fkoAnalyticsPrefix} = useGetSport();
  const {siteId, playersArray} = useAppSelector((state) => state.fkoFormDataReducer);
  const player = playersArray.find((player) => player.playerId === getUrlSearchParam('playerId'));

  useEffect(() => {
    logAnalyticsEvent(`${fkoAnalyticsPrefix}_session_scheduling_view`);
  }, [fkoAnalyticsPrefix]);

  useEffect(() => {
    if (!player || !siteId) {
      navigate(basePath, {replace: true});
    } else if (player.appointmentConfirmed || !player.eligibility?.isEligible) {
      navigate(`${basePath}/players`, {replace: true});
    }
  }, [basePath, navigate, player, siteId]);

  useEffect(() => {
    dispatch(setFkoCurrentPage(FKOPages.SCHEDULE));
    return () => {
      dispatch(setFkoCurrentPage(''));
    };
  }, [dispatch]);

  const onSubmit = (session: Session) => {
    // remove chosen appt from the pool
    dispatch(removeFkoAppointment({session}));

    // if there is an existing, working appointment, return it to the pool
    if (player && !player.error && player?.selectedSession) {
      dispatch(addFkoAppointment({session: player.selectedSession}));
    }

    dispatch(updateFkoPlayer({playerId: player?.playerId ?? '', update: {selectedSession: session, error: undefined}}));
    navigate(`${basePath}/players`);
  };

  if (!player) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>MyTOCA | Select {sessionTypeName} Time</title>
      </Helmet>
      <div className={styles.container}>
        <SchedulingForm player={player} onSubmit={onSubmit} />
      </div>
    </>
  );
};
