import {useState} from 'react';
import {Team} from 'user/user.interface';
import {ReactComponent as InviteIcon} from '../../../assets/img/icons/invite-icon.svg';
import {useProfiles} from 'api/User/useProfiles';
import {isDirector} from 'user/user-utils';

import {AddTeamToClubModal} from 'pages/Director/AddTeamModal/AddTeamToClubModal';
import {Club} from 'api/Director/club.interface';

export const TeamsView = ({
  teams,
  onTeamSelect,
  logout,
  role,
  club,
}: {
  loading: boolean;
  teams: Team[] | undefined;
  onTeamSelect: (teamId: string) => void;
  logout: () => void;
  role: string;
  club?: Club;
}) => {
  const [showAddTeamModal, setShowAddTeamModal] = useState<boolean>(false);
  const {currentProfile} = useProfiles();
  const isDirectorRole = isDirector(currentProfile);

  return (
    <>
      <div className="flex flex-col items-center">
        <div className="w-full flex flex-col justify-center items-center lg:items-start mt-4 ">
          <div className="text-xl text-primary font-bold">SELECT TEAM</div>
          <div className="flex flex-row flex-wrap lg:justify-start justify-center mt-4">
            {!teams?.length ? (
              <div className="flex flex-col">
                <div className="text-base">This {role} is not part of any team</div>
                <div className="text-primary text-base mt-2 cursor-pointer" onClick={() => logout()}>
                  Logout
                </div>
              </div>
            ) : (
              teams?.map((team) => {
                const teamName = team?.teamName || '';
                return (
                  <div
                    onClick={() => onTeamSelect(team._id)}
                    key={team._id}
                    data-test-id={teamName}
                    className="my-4 mr-2 md:mr-4 p-2 rounded-md border-grey border flex flex-col items-center cursor-pointer shadow-lg hover:bg-grey-xlight"
                  >
                    <img
                      src={club ? club.logoUrl : team?.logoUrl}
                      className="object-cover rounded-md mt-4 h-16 w-16 md:h-20 md:w-20"
                    />
                    <div className="text-sm text-center w-16 md:w-28 mt-2 line-clamp-2 " title={teamName}>
                      {teamName}
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
      {isDirectorRole && club && (
        <>
          <AddTeamToClubModal
            showModal={showAddTeamModal}
            onClose={() => setShowAddTeamModal(false)}
            clubId={club._id}
          />
          <div className="fixed md:absolute right-2 md:right-6 bottom-8 cursor-pointer">
            <InviteIcon data-test-id="add-team" onClick={() => setShowAddTeamModal(true)} />
          </div>
        </>
      )}
    </>
  );
};
