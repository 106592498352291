import {SubmitHandler, useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import Spinner from 'components/Loader/Spinner';
import {FormButton} from 'components/Forms/FormButton/FormButton';
import {FormSelect} from 'components/Forms/FormSelect/FormSelect';
import {FormInput} from 'components/Forms/FormInput/FormInput';
import {locations} from '../utils';
import {TocaLocation} from 'constants/locations';
import {PHONE_REGEX} from 'pages/RegistrationLanding/RegistrationLandingForm';

// Constants for error messages
const ERROR_MESSAGES = {
  email: {
    invalid: 'Email is invalid',
    required: 'Email is required',
    noPlus: 'Email must not contain "+" signs',
  },
  firstName: 'Please enter your first name',
  lastName: 'Please enter your last name',
  phoneNumber: 'Phone number is required',
  location: 'Required',
};

// Validation schema
const getValidationSchema = (presetLocation?: TocaLocation) => {
  const schema: any = {
    email: yup
      .string()
      .email(ERROR_MESSAGES.email.invalid)
      .required(ERROR_MESSAGES.email.required)
      .matches(/^[^+]+$/, ERROR_MESSAGES.email.noPlus),
    firstName: yup.string().trim('Name cannot include leading or trailing spaces').required(ERROR_MESSAGES.firstName),
    lastName: yup.string().trim('Name cannot include leading or trailing spaces').required(ERROR_MESSAGES.lastName),
    phoneNumber: yup.string().required(ERROR_MESSAGES.phoneNumber).matches(PHONE_REGEX, 'Must be a valid phone number'),
  };

  if (!presetLocation) {
    schema.location = yup
      .string()
      .required(ERROR_MESSAGES.location)
      .notOneOf(['disabled-placeholder'], 'Please choose a location');
  }

  return yup.object().shape(schema);
};

export interface PlayerContactDetailsFormSubmission {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  location: string;
}

interface PlayerContactDetailsFormProps {
  onSubmitHandler: SubmitHandler<PlayerContactDetailsFormSubmission>;
  isLoading?: boolean;
  errorMessage?: string;
  presetLocation?: TocaLocation;
  defaultValues?: Omit<PlayerContactDetailsFormSubmission, 'location'>;
}

const locationOptions = locations.map((location) => ({
  id: location.siteId,
  value: location.siteId,
  displayValue: location.name,
}));

export const PlayerContactDetailsForm = ({
  onSubmitHandler,
  isLoading,
  errorMessage,
  presetLocation,
  defaultValues,
}: PlayerContactDetailsFormProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm<PlayerContactDetailsFormSubmission>({
    mode: 'onChange',
    resolver: yupResolver(getValidationSchema(presetLocation)),
    defaultValues,
  });

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)} className="grow w-full">
      <div data-form-section="center-name" className="mb-4 last:border-b last:border-black/10`">
        {presetLocation && (
          <div className="text-black/80 text-base font-medium p-2 border-t border-black/10">
            Your center: {presetLocation.name}
          </div>
        )}
      </div>
      <div className="form-fields-wrapper">
        <FormInput
          spellCheck="false"
          {...register('firstName')}
          type="text"
          id="firstName"
          placeholder="First Name"
          hasError={!!errors.firstName?.message}
        />
        <p className="text-xs text-alert mb-1">{errors.firstName?.message ?? '\u00A0'}</p>

        <FormInput
          spellCheck="false"
          {...register('lastName')}
          type="text"
          id="lastName"
          placeholder="Last Name"
          hasError={!!errors.lastName?.message}
        />
        <p className="text-xs text-alert mb-1">{errors.lastName?.message ?? '\u00A0'}</p>

        <FormInput
          spellCheck="false"
          {...register('email', {setValueAs: (val) => val.toLowerCase()})}
          type="email"
          id="email"
          placeholder="Email"
          hasError={!!errors.email?.message}
        />
        <p className="text-xs text-alert mb-1">{errors.email?.message ?? '\u00A0'}</p>

        <FormInput
          spellCheck="false"
          {...register('phoneNumber')}
          type="tel"
          id="phoneNumber"
          placeholder="Phone Number"
          hasError={!!errors.phoneNumber?.message}
        />
        <p className="text-xs text-alert mb-1">{errors.phoneNumber?.message ?? '\u00A0'}</p>
        {!presetLocation ? (
          <>
            <FormSelect
              {...register('location')}
              id="location"
              hasError={!!errors.location}
              options={locationOptions}
              placeholderValue="Select Training Center"
              defaultValue={'disabled-placeholder'}
              onChange={(e) => setValue('location', e.currentTarget.value, {shouldValidate: true})}
            />
            <p className="text-xs text-alert mb-1">{errors.location?.message ?? '\u00A0'}</p>
          </>
        ) : (
          <input type="hidden" {...register('location')} value={presetLocation.siteId} />
        )}
      </div>
      <div className="h-[13px] block" />
      <p className="text-xs text-alert mb-1">{errorMessage ?? '\u00A0'}</p>
      <div className="h-[13px] block" />{' '}
      {isLoading ? (
        <div className="flex items-center justify-center w-full h-[48px] rounded-[12px] bg-primary">
          <Spinner darkMode />
        </div>
      ) : (
        <FormButton type="submit" isPrimary hasError={Object.keys(errors).length !== 0}>
          TRY FOR FREE
        </FormButton>
      )}
    </form>
  );
};
