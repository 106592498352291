import {useCallback, useEffect, useState} from 'react';
import {PhoneAuthProvider, updatePhoneNumber} from 'firebase/auth';
import {auth as firebaseAuth} from '../../firebase/config';
import {StandardButton} from '../Buttons/Standard/StandardButton';
import {useUpdateProfile} from 'api/User/useUpdateProfile';
import {useProfiles} from 'api/User/useProfiles';
import {updateUserAccount} from 'api/api';
import {BasePhoneVerification} from 'pages/SignInSignUp/PhoneNumberAddVerification/BasePhoneVerification';

export const AddUpdatePhoneNumber = () => {
  const {currentProfile} = useProfiles();
  const [currentPhoneNumber, setCurrentPhoneNumber] = useState('');
  const [isManagingPhoneNumber, setIsManagingPhoneNumber] = useState(false);
  const {updateProfile} = useUpdateProfile();

  useEffect(() => {
    const user = firebaseAuth.currentUser;
    if (user?.phoneNumber) {
      setCurrentPhoneNumber(user.phoneNumber);
    }
  }, []);

  const handleVerificationComplete = useCallback(
    async (phoneNumber: string, verificationId: string, verificationCode: string) => {
      const user = firebaseAuth.currentUser;
      if (!user) {
        throw new Error('Missing user information');
      }

      const credential = PhoneAuthProvider.credential(verificationId, verificationCode);
      await updatePhoneNumber(user, credential);

      await Promise.all([
        updateProfile({
          profileId: currentProfile._id,
          email: currentProfile.email ?? '',
          update: {phoneNumber},
        }),
        updateUserAccount({phoneNumber}),
      ]);

      setIsManagingPhoneNumber(false);
    },
    [currentProfile, updateProfile]
  );

  // only allow account holder profile to add/update the phone number
  if (!currentProfile.accountHolder) {
    return null;
  }

  if (process.env.REACT_APP_PHONE_ADD_SIGN_IN_ENABLED == 'false') {
    return null;
  }

  return (
    <>
      {currentPhoneNumber && (
        <p className="text-md text-gray-600 my-2">
          Current phone number: <span className="font-semibold">{currentPhoneNumber}</span>
        </p>
      )}
      {isManagingPhoneNumber ? (
        <BasePhoneVerification onVerificationComplete={handleVerificationComplete} buttonText="Verify Phone Number" />
      ) : (
        <StandardButton
          variant="light"
          text={currentPhoneNumber ? 'Update Phone Number' : 'Add Phone Number'}
          onClick={() => setIsManagingPhoneNumber(true)}
          customClass="mt-2"
        />
      )}
    </>
  );
};

AddUpdatePhoneNumber.displayName = 'AddUpdatePhoneNumber';
