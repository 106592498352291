import {useMutation} from '@tanstack/react-query';
import {updateTeam as updateTeamApi} from 'api/api';
import {useCallback} from 'react';
import {Team} from 'user/user.interface';

export const useUpdateTeam = (onSuccessHandler: () => void, onErrorHandler: () => void, role: string) => {
  // Mutation for updating team details
  const mutateUpdateTeam = useMutation<void, unknown, Partial<Team>>({
    mutationFn: (updatedTeam) => updateTeamApi(updatedTeam, role).then((res: any) => res.data),
  });

  // Function to update team details and then the team logo
  const updateTeam = useCallback(
    async ({teamDetails}: {teamDetails: Partial<Team>; teamId: string}) => {
      try {
        // Step 1: Update Team Details
        await mutateUpdateTeam.mutateAsync(teamDetails);

        onSuccessHandler();
      } catch (error) {
        onErrorHandler();
      }
    },
    [mutateUpdateTeam, onSuccessHandler, onErrorHandler]
  );

  return {
    updateTeam,
    loading: mutateUpdateTeam.isLoading,
  };
};
