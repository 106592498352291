import {useQuery} from '@tanstack/react-query';
import {getTrainerPlayers} from '../api';

// TODO switch to trainerStaffId for querykey parity
export const useGetTrainerPlayers = ({trainerId}: {trainerId?: string}) => {
  return useQuery({
    queryKey: ['trainer', trainerId, 'players-list'],
    queryFn: () => getTrainerPlayers(),
    enabled: !!trainerId,
  });
};
