import {useGetSport} from 'common/useGetSport';
import SessionPreparationContent from 'components/Programs/SessionPreparationContent';
import SessionExpectationContent from 'components/Programs/SessionExpectationContent';
import {Testimonial} from 'components/Testimonial/Testimonial';
import {ReactComponent as FootballIcon} from 'assets/img/icons/football.svg';

const testimonials = [
  {
    nStars: 5,
    text: `I love the energy of the staff and coaches; I love how friendly and flexible the team is in order to accommodate the little players and their families; I love how happy my daughter is when she's running around on the field!`,
    personName: 'Kim',
  },
  {
    nStars: 5,
    text: `I love that the kids are constantly moving and having fun while learning from skilled coaches about technical and tactical aspects of soccer.`,
    personName: 'Alison',
  },
];

export const FTCPostConfirmationContent = () => {
  const {isFreeTrialClasses} = useGetSport();

  if (!isFreeTrialClasses) {
    return null;
  }

  return (
    <div className="text-sm">
      <SessionPreparationContent />
      <SessionExpectationContent />
      <div>
        <p className="mt-8 text-center text-lg font-semibold">{`Get Ready to Unlock Your Child's Potential`}</p>
        <div className="mt-4">
          TOCA Soccer classes not only teach soccer skills, but encourage teamwork, fitness, and a love of the game
          <FootballIcon className="ml-1 w-5 h-5 inline" />
        </div>
        <p className="mt-4">We’re excited to meet you and your player at their free class!</p>
      </div>
      <div className="mt-10 max-w-[600px] mx-auto text-center">
        <p className="mb-4 text-center text-lg font-semibold">{`Trusted by MLS and Loved by Parents`}</p>
        {testimonials.map((testimonial) => (
          <Testimonial key={testimonial.personName} {...testimonial} />
        ))}
        <p className="mt-4">
          Our commitment to growing the game of soccer led us to become an official soccer training partner of MLS.
        </p>
      </div>
    </div>
  );
};
