import {useGetSport} from 'common/useGetSport';

const FkoVideoEmbed = () => {
  const {isFreeBaselineSession} = useGetSport();

  if (!isFreeBaselineSession) {
    return null;
  }

  const content = (
    <div className="mt-3 mb-2">
      <h1 className="font-helvetica text-black text-center text-[26px] font-light">
        Fuel Your Player’s On-Field Success!
      </h1>
      <p className="text-sm text-center mt-4">
        TOCA Training is an Official Soccer Training Partner of MLS and celebrated by parents.
      </p>
      <iframe
        className="mt-4"
        width="100%"
        height="315"
        src="https://www.youtube.com/embed/jIfsZBX-yCA?si=v7L0eKV2YJmpuu6G"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
    </div>
  );

  return content;
};

export default FkoVideoEmbed;
