import {useGetSport} from 'common/useGetSport';
import {FormSectionHeading} from '../../../../components/Forms/FormSectionHeading/FormSectionHeading';
import styles from '../FkoConfirmationPage.module.scss';

const FkoEndOfPageMessage = () => {
  const {isFreeBaselineSession} = useGetSport();

  const renderContent = () => {
    if (isFreeBaselineSession) {
      return (
        <>
          <p className="text-sm text-center mt-4">TOCA Individual Training offers the fastest player improvement.</p>
          <p className="text-sm text-center mt-4">See you at your upcoming session!</p>
        </>
      );
    }

    return (
      <>
        <FormSectionHeading text="play the toca way" />
        <div className={styles.tokaWay}>
          <h2>The TOCA Experience</h2>
          <p>
            There are many sides to the TOCA experience. From skills training that helps players take the next step to
            data-driven games that turn soccer into immersive entertainment. From competitive soccer leagues at every
            level to programs that teach kids lessons in a fun environment. No matter how you play, there&apos;s a TOCA
            experience for you.
          </p>
        </div>
      </>
    );
  };

  return renderContent();
};

export default FkoEndOfPageMessage;
