import {Outlet} from 'react-router-dom';
import {ExistingPlayerOnboardingContainer} from 'components/PageContainer/ExistingPlayerOnboardingContainer';

export const ExistingPlayerOnboardingLayout = () => {
  return (
    <ExistingPlayerOnboardingContainer>
      <Outlet />
    </ExistingPlayerOnboardingContainer>
  );
};
