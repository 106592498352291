import {memo} from 'react';
import CommonRegistrationPage from 'components/Forms/CommonRegistrationForm/CommonRegistrationPage';
import {
  getFreePickupAndLeaguesFormDataForSubmission,
  getFreePickupAndLeaguesFormFields,
} from 'common/freePickupandLeaguesUtils';
import {FreeTrialProgram, relevantFreePickupSoccerAndLeaguesUrlParams} from 'common/fko-utils';
import {tocaLocations} from 'constants/locations';

const locationsAllowed = [...tocaLocations].filter((loc) => loc.pickupSoccerSupported).map((loc) => loc.hubspotName);

const FreePickupSoccerRegistrationPage = memo(() => {
  return (
    <CommonRegistrationPage
      programName={FreeTrialProgram.PICKUP_SOCCER}
      locationsAllowed={locationsAllowed}
      formId={process.env.REACT_APP_HUBSPOT_FREE_PICKUP_SOCCER_FORM_ID ?? ''}
      relevantUrlParams={relevantFreePickupSoccerAndLeaguesUrlParams}
      getFormFields={getFreePickupAndLeaguesFormFields}
      getHubspotFormDataForSubmission={getFreePickupAndLeaguesFormDataForSubmission}
    />
  );
});

FreePickupSoccerRegistrationPage.displayName = 'FreePickupSoccerRegistrationPage';

export default FreePickupSoccerRegistrationPage;
