import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {useCallback} from 'react';
import {
  getStudioSelection,
  getTrainerAvailableStudios,
  saveErrorDetails,
  setStudioSelection,
  SetStudioSelectionParams,
} from '../api';
import {useProfiles} from 'api/User/useProfiles';

export interface Studio {
  studioId: string;
  mboStudioId?: string;
  siteId: string;
  name: string;
  isAvailable?: boolean;
}

export interface TrainerStudioSelectionType {
  _id: string;
  staffId: string;
  staffFirstName?: string;
  staffLastName?: string;
  siteId: string;
  studioId: string;
  mboStudioId?: string;
  studioName: string;
  startDateTime: string;
  endDateTime: string;
  appointmentErrors?: Array<{
    appointment?: {
      appointmentId: string;
      sessionTypeName?: string;
      startDateTime?: string;
      clientFirstName?: string;
      clientLastName?: string;
    };
    message?: string;
  }>;
}

export const useStudioSelection = ({trainerStaffId, siteId}: {trainerStaffId: string; siteId: string}) => {
  const queryClient = useQueryClient();
  const {currentProfile} = useProfiles();

  const {data: currentSelection, isLoading: isLoadingCurrentStudio} = useQuery({
    queryKey: ['trainer', trainerStaffId, 'studio-selection'],
    queryFn: () => getStudioSelection({trainerStaffId, siteId}).then((res) => res),
    enabled: !!trainerStaffId,
  });

  const {data: availableStudios, isLoading: isLoadingAvailableStudios} = useQuery<Studio[]>({
    queryKey: ['trainer', trainerStaffId, 'studio-availability'],
    queryFn: () => getTrainerAvailableStudios({trainerStaffId, siteId}).then((res) => res.availableStudios ?? []),
    enabled: !!trainerStaffId,
  });

  const mutateStudioSelection = useMutation<unknown, unknown, SetStudioSelectionParams>({
    mutationFn: (studioSelectionParams) =>
      setStudioSelection(studioSelectionParams).then(({studioSelection}) =>
        queryClient.setQueryData(['trainer', trainerStaffId, 'studio-selection'], studioSelection)
      ),
    onSuccess: () => queryClient.invalidateQueries({queryKey: ['trainer', trainerStaffId, 'studio-selection']}),
    onError: (errorContext) => {
      saveErrorDetails({
        key: 'useStudioSelection-mutate-error',
        severity: 'medium',
        message: 'Error occurred selecting a studio for the day',
        context: 'trainer',
        siteId,
        url: window.location.href,
        data: {
          errorContext,
          trainerStaffId,
        },
      });
    },
  });

  const selectStudio = useCallback(
    async ({studioId}: Pick<SetStudioSelectionParams, 'studioId'>) => {
      const params = {siteId, studioId, trainerStaffId, profileId: currentProfile._id};
      return mutateStudioSelection.mutate(params);
    },
    [mutateStudioSelection, currentProfile, siteId, trainerStaffId]
  );

  return {
    currentSelection: currentSelection?.studioSelection,
    referenceDate: currentSelection?.referenceDate,
    availableStudios,
    selectStudio,
    isLoading:
      trainerStaffId &&
      siteId &&
      (mutateStudioSelection.isLoading || isLoadingCurrentStudio || isLoadingAvailableStudios),
    isLoadingSelectStudio: mutateStudioSelection.isLoading,
    isError: mutateStudioSelection.isError,
  };
};
