import {useMutation} from '@tanstack/react-query';
import {updateClub as updateClubApi, updateClubLogo} from 'api/api';
import {useCallback} from 'react';
import {Club} from 'api/Director/club.interface';

export const useUpdateClub = (onSuccessHandler: () => void, onErrorHandler: () => void, role: string) => {
  // Mutation for updating club details
  const mutateUpdateClub = useMutation<void, unknown, Partial<Club>>({
    mutationFn: (updatedClub) => updateClubApi(updatedClub, role).then((res: any) => res.data),
  });

  // Mutation for updating club logo
  const mutateUpdateClubLogo = useMutation<void, unknown, FormData>({
    mutationFn: (formData) => updateClubLogo(formData, role).then((res: any) => res.data),
  });

  // Function to update club details and then the club logo
  const updateClubAndLogo = useCallback(
    async ({clubDetails, clubId, logo}: {clubDetails: Partial<Club>; clubId: string; logo: File | string}) => {
      try {
        // Step 1: Update Club Details
        await mutateUpdateClub.mutateAsync(clubDetails);

        // Step 2: Update Club Logo if available
        if (clubId && logo instanceof File) {
          const formData = new FormData();
          formData.append('file', logo as File);
          formData.append('clubId', clubId as string);
          await mutateUpdateClubLogo.mutateAsync(formData);
        }

        // Show success toast if both operations succeed
        onSuccessHandler();
      } catch (error) {
        onErrorHandler();
      }
    },
    [mutateUpdateClub, mutateUpdateClubLogo, onSuccessHandler, onErrorHandler]
  );

  return {
    updateClubAndLogo,
    loading: mutateUpdateClub.isLoading || mutateUpdateClubLogo.isLoading,
  };
};
