import {memo, ReactNode} from 'react';

export const ExistingPlayerOnboardingContainer = memo(({children}: {children: ReactNode}) => {
  return (
    <div className="min-h-screen max-w-[600px] mx-auto shadow-pageLayout bg-half-blue-gradient-half-white">
      {children}
    </div>
  );
});
ExistingPlayerOnboardingContainer.displayName = 'ExistingPlayerOnboardingContainer';
