import {useNavigate} from 'react-router-dom';
import {useGetCoachInfo} from 'api/Coach/useGetCoachInfo';
import {useProfiles} from 'api/User/useProfiles';
import {useLogout} from 'hooks/useLogout';
import {roleType} from 'constants/constants';
import {TeamsView} from './Teams.view';
import {LogoBanner} from 'components/Connects/LogoBanner';
import {PageContainer} from 'components/PageContainer/RevisedPageContainer';
import Spinner from 'components/Loader/Spinner';

export const CoachTeams = () => {
  const navigate = useNavigate();
  const {currentProfile} = useProfiles();
  const {data: coachInfo, isLoading} = useGetCoachInfo(currentProfile?._id);
  const {logout} = useLogout();

  const teams = coachInfo?.teams;

  const onTeamSelect = (teamId: string) => {
    navigate(`teams/${teamId}`);
  };

  return (
    <PageContainer>
      <LogoBanner />
      {isLoading ? (
        <div className="w-full h-screen flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <TeamsView
          onTeamSelect={onTeamSelect}
          teams={teams}
          logout={logout}
          loading={isLoading}
          role={roleType.teamCoach}
        />
      )}
    </PageContainer>
  );
};
