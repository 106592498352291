import {memo} from 'react';
import CommonRegistrationPage from 'components/Forms/CommonRegistrationForm/CommonRegistrationPage';
import {
  getFreePickupAndLeaguesFormDataForSubmission,
  getFreePickupAndLeaguesFormFields,
} from 'common/freePickupandLeaguesUtils';
import {FreeTrialProgram, relevantFreePickupSoccerAndLeaguesUrlParams} from 'common/fko-utils';
import {tocaLocations, tocaLocationsWithoutWorkingMboSiteId} from 'constants/locations';
import {isDevelopmentEnvironment} from 'common/isTestEnvironment';

const locationsAllowed = [...tocaLocations, ...tocaLocationsWithoutWorkingMboSiteId]
  .filter((loc) => loc.leaguesSupported)
  .map((loc) => loc.hubspotName);

const FreeLeaguesRegistrationPage = memo(() => {
  return (
    <CommonRegistrationPage
      programName={FreeTrialProgram.LEAGUES_TRIAL}
      locationsAllowed={locationsAllowed}
      formId={process.env.REACT_APP_HUBSPOT_FREE_LEAGUES_FORM_ID ?? ''}
      relevantUrlParams={relevantFreePickupSoccerAndLeaguesUrlParams}
      getFormFields={getFreePickupAndLeaguesFormFields}
      getHubspotFormDataForSubmission={getFreePickupAndLeaguesFormDataForSubmission}
    />
  );
});

FreeLeaguesRegistrationPage.displayName = 'FreeLeaguesRegistrationPage';

export default FreeLeaguesRegistrationPage;
