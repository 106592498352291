import * as yup from 'yup';
import {FormFieldConfig} from 'components/Forms/CommonRegistrationForm/CommonRegistrationForm';
import {PHONE_REGEX} from 'utils/utils';
import {
  GetHubspotFormDataForSubmissionProps,
  GetFormFieldsProps,
} from 'components/Forms/CommonRegistrationForm/CommonRegistrationPage';
import {TrackingStrings} from 'redux/slices/fkoFormData';
import {tocaLocations, tocaLocationsWithoutWorkingMboSiteId} from 'constants/locations';

export type FtcDsrUrlParams = {
  [key in TrackingStrings]?: string;
} & {
  locations__drop_down_?: string;
};

export const getFTCDSRFormDataForSubmission = ({
  validLocation,
  urlParams,
  formData,
}: GetHubspotFormDataForSubmissionProps<FtcDsrUrlParams>): Record<string, string> => {
  const {locations__drop_down_, ...otherUrlParams} = urlParams;
  const selectedLocation = [...tocaLocations, ...tocaLocationsWithoutWorkingMboSiteId].find(
    (location) => location.siteId === formData.location
  );
  const locationHubspotName = selectedLocation?.hubspotName;

  const hubSpotData: Record<string, string> = {
    firstname: formData.firstname,
    lastname: formData.lastname,
    email: formData.email,
    phone: formData.phone,
    locations__drop_down_: validLocation || locationHubspotName || '',
    name_of_child: formData.childname,
    date_of_birth: formData.childage.toString(),
    ...otherUrlParams,
  };

  return hubSpotData;
};

export const getFTCDSRFormFields = ({validLocation, locationsAllowed}: GetFormFieldsProps): FormFieldConfig[] => {
  const fields: FormFieldConfig[] = [
    {
      name: 'firstname',
      type: 'text',
      placeholder: 'Parent/Guardian: First Name',
      validation: yup.string().trim().required('Please enter your first name'),
    },
    {
      name: 'lastname',
      type: 'text',
      placeholder: 'Parent/Guardian: Last Name',
      validation: yup.string().trim().required('Please enter your last name'),
    },
    {
      name: 'email',
      type: 'email',
      placeholder: 'Email',
      validation: yup
        .string()
        .email('Email is invalid')
        .required('Email is required')
        .matches(/^[^+]+$/, 'Email must not contain "+" signs'),
    },
    {
      name: 'phone',
      type: 'tel',
      placeholder: 'Phone Number',
      validation: yup
        .string()
        .required('Phone number is required')
        .matches(PHONE_REGEX, 'Must be a valid phone number'),
    },
    {
      name: 'childname',
      type: 'text',
      placeholder: "Child's Name",
      validation: yup.string().required("Child's name is required"),
    },
    {
      name: 'childage',
      type: 'number',
      placeholder: "Child's Age",
      validation: yup
        .number()
        .typeError("Child's age is required")
        .required("Child's age is required")
        .positive('Please enter a valid age')
        .integer('Please enter a valid age'),
    },
  ];

  if (!validLocation) {
    fields.push({
      name: 'location',
      type: 'select',
      placeholder: 'Select Location',
      validation: yup
        .string()
        .required('Please select a location')
        .notOneOf(['disabled-placeholder'], 'Please choose a location'),
      options: locationsAllowed.map((location) => ({
        id: location,
        value: location,
        displayValue: location,
        hubspotName: location,
      })),
    });
  }

  return fields;
};
