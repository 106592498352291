import {memo, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {StandardInput} from 'components/Input/StandardInput/StandardInput';
import {usePhoneVerification} from 'hooks/usePhoneVerification';
import formStyles from '../EmailSignInForm.module.scss';
import {BookingFormButton} from 'components/Buttons/Form/FormButton';

interface FormTypes {
  phoneNumber: string;
  verificationCode: string;
}

interface BasePhoneVerificationProps {
  onVerificationComplete: (phoneNumber: string, verificationId: string, verificationCode: string) => Promise<void>;
  buttonText: string;
}

export const BasePhoneVerification = memo(({onVerificationComplete, buttonText}: BasePhoneVerificationProps) => {
  const {state, handlePhoneVerification, setState, handleVerificationError, clearError} = usePhoneVerification();

  const validationSchema = yup.object().shape({
    phoneNumber: yup
      .string()
      .required('Phone number is required')
      .matches(/^\+[1-9]\d{1,14}$/, 'Phone number must be in this format: +1234567890 (no spaces or dashes)'),
    verificationCode: yup.string().matches(/^\d{6}$/, 'Verification code must be 6 digits'),
  });

  const form = useForm<FormTypes>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const onPhoneSubmit = async ({phoneNumber}: FormTypes) => {
    setState((prev) => ({...prev, phoneNumber}));
    await handlePhoneVerification(phoneNumber);
  };

  const handleVerificationCodeSubmit = async () => {
    const verificationCode = form.getValues('verificationCode');
    if (!verificationCode) {
      return;
    }
    try {
      setState((prev) => ({...prev, isSubmitting: true, error: ''}));
      if (!state.verificationId) {
        throw new Error('Verification session expired');
      }
      await onVerificationComplete(state.phoneNumber, state.verificationId, verificationCode);
    } catch (error: any) {
      handleVerificationError(error);
    } finally {
      setState((prev) => ({...prev, isSubmitting: false}));
    }
  };

  useEffect(() => {
    const subscription = form.watch((value, {name}) => {
      // Clear state error when any field changes
      if (state.error) {
        clearError();
      }
    });

    return () => subscription.unsubscribe();
  }, [form, state.error, clearError]);

  const ErrorMessage = ({message}: {message: string}) => (
    <p className="!text-[#e64040] text-sm mt-1 mb-2 ml-2 !whitespace-normal !line-clamp-2">{message}</p>
  );

  return (
    <div className="flex flex-col mt-4">
      <div id="recaptcha-container" />

      {!state.showVerificationInput ? (
        <form onSubmit={form.handleSubmit(onPhoneSubmit)} className={formStyles.container}>
          <StandardInput
            type="tel"
            {...form.register('phoneNumber')}
            placeholder="Phone Number (+1234567890)"
            hasError={!!form.formState.errors.phoneNumber?.message}
            disabled={state.isSubmitting}
          />
          {form.formState.errors.phoneNumber?.message && (
            <ErrorMessage message={form.formState.errors.phoneNumber.message} />
          )}
          {state.error && <ErrorMessage message={state.error} />}
          <div className={`${formStyles.buttonWrapper} mt-4`}>
            <BookingFormButton
              type="submit"
              hasError={!!state.error || !!form.formState.errors.phoneNumber}
              isPrimary
              disabled={state.isSubmitting || !!form.formState.errors.phoneNumber}
            >
              <p className={`${formStyles.buttonText} !text-white`}>
                {state.isSubmitting ? 'Sending code...' : buttonText}
              </p>
            </BookingFormButton>
          </div>
        </form>
      ) : (
        <form onSubmit={form.handleSubmit(handleVerificationCodeSubmit)} className={formStyles.container}>
          <p className="mb-4 text-lg text-gray-600">Enter verification code sent to {state.phoneNumber}</p>

          <StandardInput
            type="text"
            {...form.register('verificationCode')}
            placeholder="Enter verification code"
            hasError={!!form.formState.errors.verificationCode?.message}
            disabled={state.isSubmitting}
          />
          {form.formState.errors.verificationCode?.message && (
            <p className={`mt-1 ml-2 !line-clamp-2 !whitespace-normal !text-[#e64040]`}>
              {form.formState.errors.verificationCode?.message}
            </p>
          )}

          {state.error && <ErrorMessage message={state.error} />}

          <div className={`${formStyles.buttonWrapper} mt-4`}>
            <BookingFormButton
              type="submit"
              hasError={!!state.error || !!form.formState.errors.verificationCode}
              isPrimary
              disabled={state.isSubmitting || !!form.formState.errors.verificationCode}
            >
              <p className={`${formStyles.buttonText} !text-white`}>
                {state.isSubmitting ? 'Verifying code...' : 'Verify Code'}
              </p>
            </BookingFormButton>
          </div>
        </form>
      )}
    </div>
  );
});

BasePhoneVerification.displayName = 'BasePhoneVerification';
