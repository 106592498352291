import styles from './FkoConfirmationPage.module.scss';
import {FormSectionHeading} from '../../../components/Forms/FormSectionHeading/FormSectionHeading';
import {Helmet} from 'react-helmet';
import {FormHeading} from '../../../components/Forms/FormHeading/FormHeading';
import {ConfirmationCard} from '../../RegistrationScheduling/ConfirmationCard';
import {FkoPlayer, setFkoCurrentPage} from '../../../redux/slices/fkoFormData';
import {formatPhoneNumber} from '../../../utils/utils';
import {Session} from '../../RegistrationScheduling/SchedulingForm';
import {useNavigate} from 'react-router-dom';
import {useEffect, useRef} from 'react';
import imageTraining from '../../../assets/img/training-2.jpeg';
import imageAction from '../../../assets/img/confirmation-action.jpg';
import {logAnalyticsEvent} from '../../../common/analytics-events';
import {useAppDispatch, useAppSelector} from '../../../redux/reduxUtils';
import {ReactComponent as FiveStars} from '../../../assets/img/icons/redesign-icons/5-star-rating.svg';
import {useGetSport} from '../../../common/useGetSport';
import {addPlayersToTeam} from 'api/api';
import {FKOPages} from 'common/fko-utils';
import FkoSubHeading from './components/FkoSubHeading';
import FkoEndOfPageMessage from './components/FkoEndOfPageMessage';
import FkoVideoEmbed from './components/FkoVideoEmbed';
import SessionPreparationContent from 'components/Programs/SessionPreparationContent';
import SessionExpectationContent from 'components/Programs/SessionExpectationContent';

type ScheduledPlayer = FkoPlayer & {
  selectedSession: Session;
};

export const FkoConfirmationPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {playersArray, userData, initialParams, siteId} = useAppSelector((state) => state.fkoFormDataReducer);
  const confirmationEventFired = useRef(false);
  const {sessionTypeName, basePath, isFreeBaselineSession, isDbat, isPlayerAssessment, fkoAnalyticsPrefix} =
    useGetSport();

  useEffect(() => {
    if (!playersArray?.length) {
      navigate(basePath, {replace: true});
      return;
    }

    if (!confirmationEventFired.current) {
      confirmationEventFired.current = true;
      const scheduledPlayers = playersArray.filter((p) => p.selectedSession);
      const email = userData?.email ?? playersArray.find((p) => p.email)?.email;
      const phone = userData?.phoneNumber ?? playersArray.find((p) => p.phoneNumber)?.phoneNumber;
      if (scheduledPlayers.length) {
        logAnalyticsEvent(`${fkoAnalyticsPrefix}_confirmation_page_view`, {
          playerIds: scheduledPlayers.map((player) => player.mboDetails?.clientId).join(','),
          profileIds: scheduledPlayers.map((player) => player._id).join(','),
          locationName: scheduledPlayers[0].selectedSession?.location?.name,
          email,
          phone,
        });
      }
    }
  }, [navigate, basePath, playersArray, fkoAnalyticsPrefix]);

  useEffect(() => {
    dispatch(setFkoCurrentPage(FKOPages.PLAYERS));
    return () => {
      dispatch(setFkoCurrentPage(''));
    };
  }, [dispatch]);

  if (!playersArray?.length) {
    return null;
  }

  const players: ScheduledPlayer[] = [];

  playersArray.forEach((player) => {
    if (player.selectedSession) {
      players.push(player as ScheduledPlayer);
    }
  });

  if (isPlayerAssessment && initialParams?.teamId && siteId) {
    const playersToBeAdded = players?.map((player) => player?.mboDetails?.clientId || '') || [];
    void addPlayersToTeam({
      teamId: initialParams.teamId,
      clientIds: playersToBeAdded,
      siteId,
    });
  }

  const location = players[0].selectedSession.location;
  const locationName = location.name === 'D-BAT Burnsville' ? 'TOCA Soccer Center Burnsville' : location.name;
  const address = `${location.address} ${location.address2}`;
  const mapSrc = `https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GMAPS_API_KEY}&q=${encodeURI(
    address
  )}`;

  let pageTitle = `MyTOCA | ${sessionTypeName} Confirmation`;
  let formHeading = `You’re all set for your upcoming ${sessionTypeName}!`;
  if (isFreeBaselineSession) {
    formHeading = `GOAAAL! Your TOCA Baseline Session is confirmed.`;
  }

  if (isDbat) {
    pageTitle = `D-Bat | ${sessionTypeName} Confirmation`;
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <div className={styles.topImage}>
        <img src={imageAction} alt="player kicking soccer ball" />
      </div>
      <div className={styles.container}>
        <section>
          <FormHeading isConfirmation heading={formHeading} subheading={<FkoSubHeading />} />
        </section>
        <SessionPreparationContent />
        <section>
          <FormSectionHeading text="Session Details" />
          {players.map((player, index) => (
            <ConfirmationCard key={index} player={player} />
          ))}
          <FormSectionHeading text="Location" />

          <div className={styles.map}>
            {mapSrc && <iframe src={mapSrc} loading="lazy" referrerPolicy="no-referrer-when-downgrade" />}
          </div>
          <div className={styles.location}>
            <h3>{locationName}</h3>
            <p>
              {location.address} {location.address2}
            </p>
            {location.phone && <p>{formatPhoneNumber(location.phone)}</p>}
          </div>
          <SessionExpectationContent />
        </section>
        <section>
          <FkoVideoEmbed />
          <div className={styles.imageWrapper}>
            <img src={imageTraining} alt="player talking with coach" />
          </div>
          <div className="flex flex-col items-center gap-2 p-2 w-full mb-2">
            <FiveStars className="h-[24px] w-[144px] fill-primary" />
            <p className="text-center font-poppins font-medium text-sm text-primary ">
              {`“The interactive sessions have been creative, fun, and greatly beneficial in improving my daughter's game.
            This is already apparent on the playing field, after only a few months of weekly sessions at TOCA.”`}
            </p>
            <p className="text-center font-poppins text-sm text-grey-xdark ">{` - Sara M, Mother of a TOCA Athlete`}</p>
          </div>
          <FkoEndOfPageMessage />
        </section>
      </div>
    </>
  );
};
