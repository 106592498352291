import {
  assessmentCAStatesAndLocationsList,
  assessmentUSStatesAndLocationsList,
  classesCAStatesAndLocationsList,
  classesUSStatesAndLocationsList,
  dbatCAStatesAndLocationsList,
  dbatUSStatesAndLocationsList,
  tocaCAStatesAndLocationsList,
  tocaUSStatesAndLocationsList,
  dbat75SwingsCAStatesAndLocationsList,
  dbat75SwingsUSStatesAndLocationsList,
  ftcDsrCAStatesAndLocationsList,
  ftcDsrUSStatesAndLocationsList,
  freeTrialPickupSoccerCAStatesAndLocationsList,
  freeTrialPickupSoccerUSStatesAndLocationsList,
  freeTrialLeaguesCAStatesAndLocationsList,
  freeTrialLeaguesUSStatesAndLocationsList,
} from 'constants/locations';
import {FormOptionGroup} from 'components/Forms/FormSelect/FormSelect';
import {countryStates} from 'common/country-states';
import {useGetSport} from 'common/useGetSport';
import {useMemo} from 'react';

/**
 * Returns preformatted location options for FKO dropdowns, specific to the current sport.
 */
export const useGetLocationFormOptionGroups = () => {
  // TODO re-eval with the inclusion of Classes
  const {sport} = useGetSport();

  const locationFormOptionGroups = useMemo(
    () =>
      sport === 'dbat75Swings'
        ? dbat75FreeSwingClaimLocationFormOptionGroups
        : sport === 'ftcDsr'
        ? ftcDsrLocationFormOptionGroups
        : sport === 'freeTrialPickupSoccer'
        ? freeTrialPickupSoccerLocationFormOptionGroups
        : sport === 'freeTrialLeagues'
        ? freeTrialLeaguesLocationFormOptionGroups
        : sport === 'dbat'
        ? dbatLocationFormOptionGroups
        : sport === 'playerAssessment'
        ? playerAssessmentLocationFormOptionGroups
        : sport === 'classes'
        ? classesLocationFormOptionGroups
        : sport === 'freeTrialClasses'
        ? classesLocationFormOptionGroups
        : tocaLocationFormOptionGroups,
    [sport]
  );

  return {locationFormOptionGroups};
};

export const getLocationFormOptionGroupsForStates = (
  statesAndLocationsList: typeof tocaUSStatesAndLocationsList,
  countryCode: string,
  countryName: string
) => {
  if (!statesAndLocationsList) {
    return [];
  }
  return statesAndLocationsList.reduce((optionGroups, stateObj) => {
    optionGroups.push({
      label: `${countryStates[countryCode][stateObj.state]}, ${countryName}`,
      options: stateObj.locations
        // .filter((loc) => loc.fkoSupported)
        .map((loc) => ({
          id: loc.siteId,
          value: loc.siteId,
          displayValue: loc.name,
          hubspotName: loc.hubspotName,
        })),
    });
    return optionGroups;
  }, [] as FormOptionGroup[]);
};

// List US states first
const tocaLocationFormOptionGroups: FormOptionGroup[] = [
  ...getLocationFormOptionGroupsForStates(tocaUSStatesAndLocationsList, 'US', 'USA'),
  ...getLocationFormOptionGroupsForStates(tocaCAStatesAndLocationsList, 'CA', 'Canada'),
];
const dbatLocationFormOptionGroups: FormOptionGroup[] = [
  ...getLocationFormOptionGroupsForStates(dbatUSStatesAndLocationsList, 'US', 'USA'),
  ...getLocationFormOptionGroupsForStates(dbatCAStatesAndLocationsList, 'CA', 'Canada'),
];
const dbat75FreeSwingClaimLocationFormOptionGroups: FormOptionGroup[] = [
  ...getLocationFormOptionGroupsForStates(dbat75SwingsUSStatesAndLocationsList, 'US', 'USA'),
  ...getLocationFormOptionGroupsForStates(dbat75SwingsCAStatesAndLocationsList, 'CA', 'Canada'),
];
const ftcDsrLocationFormOptionGroups: FormOptionGroup[] = [
  ...getLocationFormOptionGroupsForStates(ftcDsrUSStatesAndLocationsList, 'US', 'USA'),
  ...getLocationFormOptionGroupsForStates(ftcDsrCAStatesAndLocationsList, 'CA', 'Canada'),
];
const freeTrialPickupSoccerLocationFormOptionGroups: FormOptionGroup[] = [
  ...getLocationFormOptionGroupsForStates(freeTrialPickupSoccerUSStatesAndLocationsList, 'US', 'USA'),
  ...getLocationFormOptionGroupsForStates(freeTrialPickupSoccerCAStatesAndLocationsList, 'CA', 'Canada'),
];
const freeTrialLeaguesLocationFormOptionGroups: FormOptionGroup[] = [
  ...getLocationFormOptionGroupsForStates(freeTrialLeaguesUSStatesAndLocationsList, 'US', 'USA'),
  ...getLocationFormOptionGroupsForStates(freeTrialLeaguesCAStatesAndLocationsList, 'CA', 'Canada'),
];
const playerAssessmentLocationFormOptionGroups: FormOptionGroup[] = [
  ...getLocationFormOptionGroupsForStates(assessmentUSStatesAndLocationsList, 'US', 'USA'),
  ...getLocationFormOptionGroupsForStates(assessmentCAStatesAndLocationsList, 'CA', 'Canada'),
];
export const classesLocationFormOptionGroups: FormOptionGroup[] = [
  ...getLocationFormOptionGroupsForStates(classesUSStatesAndLocationsList, 'US', 'USA'),
  ...getLocationFormOptionGroupsForStates(classesCAStatesAndLocationsList, 'CA', 'Canada'),
];
