import {SubmitHandler, useForm} from 'react-hook-form';
import {useState} from 'react';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {ErrorMessage} from 'components/Forms/ErrorMessage/ErrorMessage';
import {StandardInput} from 'components/Input/StandardInput/StandardInput';
import {FormButton} from 'components/Forms/FormButton/FormButton';
import Spinner from 'components/Loader/Spinner';

interface AccountPasswordFormData {
  password: string;
}

export const AccountPasswordForm = ({
  email,
  onSubmit,
}: {
  email: string;
  onSubmit: (password: string) => Promise<void>;
}) => {
  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .required('Password is required')
      .min(6, 'Password must be at least 6 characters')
      .max(40, 'Password must not exceed 40 characters'),
  });

  const {
    register,
    handleSubmit,
    formState: {errors},
    setError,
  } = useForm<AccountPasswordFormData>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const [isLoading, setIsLoading] = useState(false);

  const onSubmitHandler: SubmitHandler<AccountPasswordFormData> = async (data) => {
    setIsLoading(true);
    try {
      await onSubmit(data.password);
    } catch (err) {
      setError('password', {
        type: 'manual',
        message: err instanceof Error ? err.message : 'An error occurred',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)} className="w-full max-w-md">
      <div className=" mb-3 p-2 pt-4">
        <div>
          <p className="text-sm mb-4 ml-2">Your email: {email}</p>
        </div>

        <StandardInput
          spellCheck="false"
          {...register('password')}
          type="password"
          id="password"
          placeholder="Password"
          hasError={!!errors.password?.message}
        />
        <ErrorMessage text={errors.password?.message ?? '\u00A0'} />
      </div>

      {isLoading ? (
        <div className="flex items-center justify-center w-full h-[48px] rounded-[12px] bg-primary">
          <Spinner darkMode />
        </div>
      ) : (
        <FormButton type="submit" isPrimary hasError={Object.keys(errors).length !== 0}>
          Submit
        </FormButton>
      )}
    </form>
  );
};
