import {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {Link} from 'react-router-dom';
import {ClassSchedule, Enrollment, EnrollmentClass} from 'api/Classes/useGetPrograms';
import {
  ClassScheduleGroup,
  combineClassAndClassScheduleIds,
  format24hrTimeString,
  parseClassAndClassScheduleIds,
} from 'utils/programs-utils';
import {Button} from 'components/Connects/Button';
import {ReactComponent as LocationPinIcon} from '../../assets/img/icons/redesign-icons/location-pin.svg';
import {RadioWrapper} from 'components/RadioButton/RadioWrapper';
import moment from 'moment';
// import {ReactComponent as CalendarIcon} from '../../assets/img/icons/redesign-icons/calendar-minimal.svg';

type ClassScheduleCardProps = {
  classScheduleGroup: ClassScheduleGroup;
  locationName?: string;
  partialBookingUrl: string;
  openContactForm: ({enrollment}: {enrollment: Enrollment | ClassSchedule | null}) => void;
};

export const ClassScheduleGroupCard = memo(
  ({classScheduleGroup, locationName, partialBookingUrl, openContactForm}: ClassScheduleCardProps) => {
    const {displayName, primaryImage, tags, classSchedules} = classScheduleGroup;

    const [selectedCombinedId, setSelectedCombinedId] = useState<string>();

    const {classScheduleId: selectedClassScheduleId, classId} = parseClassAndClassScheduleIds({
      combinedIds: selectedCombinedId,
    });
    const selectedClassSchedule = classSchedules?.find((cs) => cs.classScheduleId === selectedClassScheduleId);

    const selectClass: React.FormEventHandler<HTMLInputElement> = useCallback(
      (e) => setSelectedCombinedId(e.currentTarget.value),
      [setSelectedCombinedId]
    );

    const availableClasses = useMemo(() => {
      const _clsArray: Array<EnrollmentClass & {combinedId: string}> = [];

      classSchedules.forEach((cs) =>
        cs.classes.forEach((cls) => {
          const combinedId = combineClassAndClassScheduleIds({
            classScheduleId: cs.classScheduleId,
            classId: cls.classId,
          });
          if (combinedId && cls.isAvailable && cls.capacity.available > 0) {
            _clsArray.push({...cls, combinedId});
          }
        })
      );

      return _clsArray.sort((a, b) => (moment(a.startDateTime).isAfter(moment(b.startDateTime)) ? 1 : -1));
    }, [classSchedules]);

    useEffect(() => {
      if (availableClasses?.length && !selectedCombinedId) {
        setSelectedCombinedId(availableClasses[0].combinedId);
      }
    }, [availableClasses, setSelectedCombinedId]);

    return (
      <div className="bg-white overflow-hidden border border-grey rounded-lg flex flex-col sm:flex-row sm:gap-2 lg:gap-4 shadow-md">
        <Link to={`${selectedClassScheduleId}`}>
          <div className="flex h-44 sm:w-52 sm:h-full md:w-44 lg:w-56">
            <img alt={`${displayName} image`} className="h-full w-full object-cover" src={primaryImage} />
          </div>
        </Link>

        <div className="grow grid grid-cols-[1fr_auto] gap-2 p-4 md:gap-1 md:p-2 lg:p-4">
          <Link to={`${selectedClassScheduleId}`} className="col-span-2 hover:underline">
            <h4 className="font-poppins font-semibold text-xl leading-[24px] text-primary">{displayName}</h4>
          </Link>

          <div className="col-span-1 sm:col-span-1 flex flex-col gap-2">
            <div className="grid grid-cols-[auto_1fr] gap-2 items-center mr-1">
              <LocationPinIcon className="h-4 w-4 min-h-4 fill-grey-xdark" />
              <p className="font-poppins font-medium text-sm text-grey-xdark">{locationName}</p>
            </div>

            <p className="font-poppins font-semibold text-sm text-grey-xdark uppercase tracking-[2px] mb-1">
              {tags?.classType}
            </p>

            {availableClasses.length ? (
              <div className="flex flex-col gap-1">
                <p className="font-poppins font-semibold text-sm text-grey-xdark">Pick a day and time</p>
                {availableClasses.map((cls) => (
                  <RadioWrapper
                    key={cls.combinedId}
                    id={cls.combinedId}
                    value={cls.combinedId}
                    checked={selectedCombinedId === cls.combinedId}
                    onChange={selectClass}
                  >
                    <p className="font-poppins font-medium text-sm text-grey-xdark">
                      {moment(cls.startDateString ?? cls.startDateTime).format('ddd, MMM D')}
                      {` at `}
                      {format24hrTimeString({time: cls.startTimeString})}
                    </p>
                  </RadioWrapper>
                ))}
              </div>
            ) : null}
          </div>

          <div className="col-span-2 lg:col-span-1 grid grid-cols-2 xs:grid-cols-[auto,auto,1fr] lg:grid-cols-1 gap-x-3 gap-y-2 items-center mt-2 md:mx-auto lg:mr-1 lg:mt-auto">
            <Link to={`${selectedClassScheduleId}`}>
              <Button text="Details" />
            </Link>

            {selectedClassSchedule?.availableCount ? (
              <Link to={`${partialBookingUrl}/${selectedClassScheduleId}/${classId}/register`}>
                <Button text="Book&nbsp;Now" variant="cta" color="primary" />
              </Link>
            ) : (
              <div>
                <Button
                  text="Book&nbsp;Now"
                  variant="cta"
                  color="grey"
                  onClick={() => openContactForm({enrollment: selectedClassSchedule ?? null})}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);
ClassScheduleGroupCard.displayName = 'ClassScheduleGroupCard';
