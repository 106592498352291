import {useGetSport} from 'common/useGetSport';
import {ReactComponent as WatchIcon} from 'assets/img/icons/redesign-icons/watch.svg';
import {ReactComponent as WaterIcon} from 'assets/img/icons/redesign-icons/water.svg';
import {ReactComponent as ParentIcon} from 'assets/img/icons/redesign-icons/parent.svg';
import {ReactComponent as ShoeIcon} from 'assets/img/icons/redesign-icons/shoe.svg';
import {ReactComponent as ClothesIcon} from 'assets/img/icons/redesign-icons/person-running.svg';

const ftcPreperationList = [
  {
    icon: ShoeIcon,
    text: 'Wear flat soccer or athletic shoes, as cleats are not allowed on the turf',
  },
  {
    icon: ClothesIcon,
    text: 'Wear comfortable athletic clothing',
  },
  {
    icon: WatchIcon,
    text: 'Be sure to arrive a few minutes early',
  },
  {
    icon: ParentIcon,
    text: 'Please be sure to stay during your child’s class',
  },
  {
    icon: WaterIcon,
    text: 'Don’t forget your water!',
  },
];

const fkoPreperationList = [
  {
    icon: ShoeIcon,
    text: 'Wear flat soccer or athletic shoes. Cleats are not allowed on the turf',
  },
  {
    icon: ClothesIcon,
    text: 'Wear comfortable athletic clothing',
  },
  {
    icon: WaterIcon,
    text: 'Don’t forget your water',
  },
  {
    icon: WatchIcon,
    text: 'Be sure to arrive at least 15 minutes early to meet your trainer',
  },
  {
    icon: ParentIcon,
    text: 'Parents - Plan to stay for your player’s first baseline session!',
  },
];

interface PreparationTipProps {
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  children: React.ReactNode;
}

const PreparationTip = ({icon: Icon, children}: PreparationTipProps) => (
  <div className="flex items-center mb-2">
    <Icon className="mr-2 w-5 h-5 inline" />
    {children}
  </div>
);

const SessionPreparationContent = () => {
  const {isFreeBaselineSession, isFreeTrialClasses} = useGetSport();

  const preparationListContent = (list: {icon: React.FC<React.SVGProps<SVGSVGElement>>; text: string}[]) => {
    return list.map(({icon, text}) => <PreparationTip icon={icon}>{text}</PreparationTip>);
  };

  const FkoSessionPreparationContent = (
    <section>
      <div className="text-sm my-4">
        <p className="text-lg text-center font-semibold mb-4">Preparing for your Baseline Session:</p>
        {preparationListContent(fkoPreperationList)}
        <p className="mt-4">
          Get ready to skyrocket skills, confidence, and love of the game with TOCA’s patented, game-changing soccer
          training.
        </p>
        <p className="mt-4">We’re excited to meet you at your private Baseline Session!</p>
      </div>
    </section>
  );

  const FTCSessionPreparationContent = (
    <section>
      <div className="text-sm my-4">
        <p className="text-lg text-center font-semibold mb-4">Preparing for your Free Class:</p>
        <>{preparationListContent(ftcPreperationList)}</>
      </div>
    </section>
  );

  return isFreeTrialClasses
    ? FTCSessionPreparationContent
    : isFreeBaselineSession
    ? FkoSessionPreparationContent
    : null;
};

export default SessionPreparationContent;
