import {useSportContext} from 'context/SportContext';
import {useEffect} from 'react';
import {Outlet} from 'react-router-dom';

export const FTCSportWrapper = () => {
  const {sport, setSport} = useSportContext();

  useEffect(() => {
    if (sport !== 'FREE_TRIAL_CLASS') {
      setSport({sport: 'FREE_TRIAL_CLASS'});
    }
  }, [setSport, sport]);

  return sport === 'FREE_TRIAL_CLASS' ? <Outlet /> : null;
};
