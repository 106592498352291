import styles from './FkoConfirmationPage/FkoConfirmationPage.module.scss';
import {FormSectionHeading} from '../../components/Forms/FormSectionHeading/FormSectionHeading';
import {Helmet} from 'react-helmet';
import {FormHeading} from '../../components/Forms/FormHeading/FormHeading';
import {ConfirmationCard} from '../RegistrationScheduling/ConfirmationCard';
import {FkoPlayer, setFkoCurrentPage} from '../../redux/slices/fkoFormData';
import {formatPhoneNumber} from '../../utils/utils';
import {Session} from '../RegistrationScheduling/SchedulingForm';
import {useNavigate} from 'react-router-dom';
import {useEffect, useRef} from 'react';
import imageTraining from '../../assets/img/dbat-confirmation-page.jpg';
import {logAnalyticsEvent} from '../../common/analytics-events';
import {useAppDispatch, useAppSelector} from '../../redux/reduxUtils';
import {ReactComponent as FiveStars} from '../../assets/img/icons/redesign-icons/5-star-rating.svg';
import {useGetSport} from 'common/useGetSport';
import {FKOPages} from 'common/fko-utils';

type ScheduledPlayer = FkoPlayer & {
  selectedSession: Session;
};

export const DbatFkoConfirmationPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {sessionTypeName, basePath} = useGetSport();

  const {playersArray, userData} = useAppSelector((state) => state.fkoFormDataReducer);
  const confirmationEventFired = useRef(false);

  useEffect(() => {
    if (!playersArray?.length) {
      navigate(basePath, {replace: true});
      return;
    }

    if (!confirmationEventFired.current) {
      confirmationEventFired.current = true;
      const scheduledPlayers = playersArray.filter((p) => p.selectedSession);
      const email = userData?.email ?? playersArray.find((p) => p.email)?.email;
      const phone = userData?.phoneNumber ?? playersArray.find((p) => p.phoneNumber)?.phoneNumber;
      if (scheduledPlayers.length) {
        logAnalyticsEvent('dbat_fko_confirmation_page_view', {
          playerIds: scheduledPlayers.map((player) => player.mboDetails?.clientId).join(','),
          profileIds: scheduledPlayers.map((player) => player._id).join(','),
          locationName: scheduledPlayers[0].selectedSession?.location?.name,
          email,
          phone,
        });
      }
    }
  }, [basePath, navigate, playersArray]);

  useEffect(() => {
    dispatch(setFkoCurrentPage(FKOPages.CONFIRMATION));
    return () => {
      dispatch(setFkoCurrentPage(''));
    };
  }, [dispatch]);

  if (!playersArray?.length) {
    return null;
  }

  const players: ScheduledPlayer[] = [];
  playersArray.forEach((player) => {
    if (player.selectedSession) {
      players.push(player as ScheduledPlayer);
    }
  });

  const location = players[0].selectedSession.location;
  // TODBAT dbat specific location name
  // const locationName = location.name === 'D-BAT Burnsville' ? 'TOCA Soccer Center Burnsville' : location.name;
  const address = `${location.address} ${location.address2}`;
  const mapSrc = `https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GMAPS_API_KEY}&q=${encodeURI(
    address
  )}`;

  return (
    <>
      <Helmet>
        <title>MyTOCA | {sessionTypeName} Confirmation</title>
      </Helmet>

      <div className={styles.topImage}>
        <img className="object-[0_-110px]" src="/images/dbat-kid-baseball-hit.jpg" alt="Baseball DBAT practice" />
      </div>
      <div className={styles.container}>
        <section>
          <FormHeading
            isConfirmation
            heading={`You're all set for your upcoming ${sessionTypeName}!`}
            subheading={
              <>
                <p className={styles.subheading}>
                  Please wear athletic shoes, as cleats are not allowed in the facility, as well as comfortable
                  clothing. Oh, and don&apos;t forget water!
                </p>
                <p className="text-fko-primary text-base mt-4">We Look Forward to Meeting You!</p>
              </>
            }
          />
        </section>
        <section>
          <FormSectionHeading text="Session Details" />
          {players.map((player, index) => (
            <ConfirmationCard key={index} player={player} />
          ))}

          <FormSectionHeading text="Location" />
          <div className={styles.map}>
            {mapSrc && <iframe src={mapSrc} loading="lazy" referrerPolicy="no-referrer-when-downgrade" />}
          </div>
          <div className={styles.location}>
            <h3>{location.name}</h3>
            <p>
              {location.address} {location.address2}
            </p>
            {location.phone && <p>{formatPhoneNumber(location.phone)}</p>}
          </div>
        </section>
        <section>
          <div className={styles.imageWrapper}>
            <img src={imageTraining} alt="player talking with coach" />
          </div>

          <div className="flex flex-col items-center gap-2 p-2 w-full mb-2">
            {/* TODBAT confirm copy */}
            <FiveStars className="h-[24px] w-[144px] fill-fko-primary" />
            <p className="text-center font-poppins font-medium text-sm text-fko-primary">
              {`“The one-on-one training sessions for my 8 year old son have been priceless. He has improved leaps and bounds, and actually looks forward to going each week.”`}
            </p>
            <p className="text-center font-poppins text-sm text-grey-xdark">-Lee</p>
            <p className="text-center font-poppins text-sm text-grey-xdark italic">5 star Google Review</p>
          </div>

          <FormSectionHeading text="play the toca way" />
          <div className={styles.tokaWay}>
            <h2>The D-BAT Experience</h2>
            <p>
              <strong>You Are One Step Closer to Achieving Your Goals!</strong>
            </p>
            <p>
              During your FREE Evaluation, our professional instructors will discuss your goals and develop a
              personalized plan to help you reach them. Whether you are looking to learn the fundamentals or get a
              college scholarship, our instructors get you to the next level.
            </p>
          </div>
        </section>
      </div>
    </>
  );
};
