import {useState} from 'react';
import {useForm} from 'react-hook-form';
import {Club} from 'api/Director/club.interface';
import ImageUpload from 'components/ImageUpload/ImageUpload';
import {FormInput} from 'components/Forms/FormInput/FormInput';
import {Button} from 'components/Connects/Button';
import {useUpdateClub} from 'api/Director/useUpdateClub';
import {roleType} from 'constants/constants';
import {toast} from 'react-toastify';
import {useQueryClient} from '@tanstack/react-query';

interface NativeInputFormData {
  name: string;
}

interface ClubFormProps {
  club: Club;
  onCancelHandler: () => void;
  loading?: boolean;
  error?: string;
}

export const ClubForm = ({club, onCancelHandler, loading = false, error = ''}: ClubFormProps) => {
  const queryClient = useQueryClient();
  const [imgSrc, setImgSrc] = useState<File | string>(club.logoUrl || '');

  const {
    register,
    handleSubmit,
    reset,
    formState: {errors, isSubmitted},
  } = useForm<NativeInputFormData>({
    defaultValues: {
      name: club.name,
    },
  });

  const onClubUpdateSuccessHandler = () => {
    queryClient.invalidateQueries({queryKey: ['director-info']});
    toast.success('Club updated successfully.');
    onCancelHandler();
  };

  const onClubUpdateErrorHandler = () => {
    toast.error('Failed to update club.');
  };

  const {updateClubAndLogo} = useUpdateClub(onClubUpdateSuccessHandler, onClubUpdateErrorHandler, roleType.director);

  const submitFormHandler = async (data: NativeInputFormData) => {
    const clubData = {
      _id: club._id,
      name: data.name.trim(),
    };
    await updateClubAndLogo({clubDetails: clubData, clubId: club._id, logo: imgSrc});
  };

  const onClickCancelHandler = () => {
    reset();
    onCancelHandler();
  };

  return (
    <form className="flex flex-col justify-center items-center" onSubmit={handleSubmit(submitFormHandler)}>
      <div className="flex justify-center items-center flex-col mb-4">
        <ImageUpload defaultImageUrl={club.logoUrl || ''} onImageSelect={setImgSrc} />
        {isSubmitted && !imgSrc && <p className="text-xs text-alert mt-1 mb-1">Club logo is required</p>}
      </div>

      <div className="w-full">
        <FormInput
          {...register('name', {required: 'Club Name is required'})}
          id="name"
          hasError={!!errors.name?.message}
          placeholder="Club Name"
          type="text"
        />
        <p className="text-xs text-alert m-1 text-left">{errors.name?.message ?? '\u00A0'}</p>
      </div>

      {error && <p className="text-xs text-alert mb-1">{error}</p>}

      <div className="mt-4 flex items-center justify-center">
        <Button
          type="submit"
          text={loading ? 'Saving...' : 'Save'}
          color="secondary"
          variant="cta"
          disabled={loading}
        ></Button>
        <Button className="ml-4" text="Cancel" color="primary" variant="standard" onClick={onClickCancelHandler} />
      </div>
    </form>
  );
};
