import {useNavigate, useSearchParams} from 'react-router-dom';
import {signInWithCustomToken, updatePassword, UserCredential} from 'firebase/auth';
import {useValidatePlayerTokenAndGetPlayerInfo} from 'api/User/useValidatePlayerTokenAndGetPlayerInfo';
import Spinner from 'components/Loader/Spinner';
import tocaNewLogo from '../../assets/img/my-toca-new-logo.svg';
import {AccountPasswordForm} from './AccountPasswordForm';
import {createUser} from 'common/fko-utils';
import {getPostLoginRedirectUri, setAccessToken, setCurrentAccountId} from 'user/user-utils';
import {auth as fireBaseAuth} from '../../firebase/config';
import {linkPlayerToAccount} from 'api/api';
import {logLoginEvent} from 'common/analytics-events';

const TocaLogo = () => <img className="my-10" src={tocaNewLogo} height={12} width={80} alt="TOCA Logo" />;

const LoadingSpinner = () => (
  <div className="bg-team-onboarding-background-gradient flex flex-col items-center justify-center min-h-screen">
    <TocaLogo />
    <Spinner darkMode />
  </div>
);

const ErrorMessageDisplay = ({message}: {message: string}) => (
  <div className="bg-team-onboarding-background-gradient flex flex-col justify-center items-center min-h-screen text-white text-xl">
    <TocaLogo />
    <p className="text-center mt-4">{message}</p>
  </div>
);

export const ExistingPlayerOnboarding = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('email') ?? '';
  const {
    data: tokenInfo,
    isError: isTokenError,
    error: tokenError,
    isFetching: isTokenFetching,
  } = useValidatePlayerTokenAndGetPlayerInfo({token});
  const navigate = useNavigate();

  const setupAccountCredentials = async (userCredential: UserCredential) => {
    setCurrentAccountId(userCredential.user.uid);
    setAccessToken((await userCredential.user.getIdTokenResult()).token);
  };

  const handleExistingAccount = async (password: string, authToken: string) => {
    const userCredential = await signInWithCustomToken(fireBaseAuth, authToken);
    await updatePassword(userCredential.user, password);
    await setupAccountCredentials(userCredential);

    logLoginEvent('signInWithCustomToken', {
      userUid: userCredential.user.uid,
      operationType: userCredential?.operationType,
    });

    return userCredential;
  };

  const handleNewAccount = async (email: string, password: string) => {
    const userCredential = await createUser(email, password);
    await setupAccountCredentials(userCredential);
    await linkPlayerToAccount();

    logLoginEvent('emailAndPassword', {
      userUid: userCredential.user.uid,
      operationType: userCredential?.operationType,
    });

    return userCredential;
  };

  const handlePasswordSubmit = async (password: string) => {
    try {
      if (!tokenInfo) {
        throw new Error('Token info is missing');
      }

      let userCredential: UserCredential;

      if (tokenInfo.accountExists) {
        if (!tokenInfo.authToken) {
          throw new Error('Firebase token is missing');
        }
        userCredential = await handleExistingAccount(password, tokenInfo.authToken);
      } else {
        userCredential = await handleNewAccount(tokenInfo.email, password);
      }
      navigate(userCredential.user.emailVerified ? getPostLoginRedirectUri() : '/verify-email');
    } catch (error: unknown) {
      console.error('Error handling password:', error);
      throw error;
    }
  };

  if (!token) {
    return <ErrorMessageDisplay message="No token provided" />;
  }

  if (isTokenFetching) {
    return <LoadingSpinner />;
  }

  if (isTokenError || !tokenInfo) {
    return <ErrorMessageDisplay message={`Can't onboard player. ${(tokenError as any)?.response?.data?.message}`} />;
  }

  return (
    <div className="flex flex-col justify-center  min-h-screen items-center">
      <img className="mt-64" src={tocaNewLogo} height={12} width={80} alt="TOCA Logo" />
      <p className="text-white font-semibold text-lg text-left mt-8">
        {tokenInfo?.accountExists ? 'Update your password' : 'Create your account'}
      </p>
      {/* Registration Form */}
      <div className={`flex flex-1 mt-8 flex-col  w-full`}>
        <div className="mr-5 ml-5 flex  flex-col items-center rounded-md p-5 bg-white relative z-20 border-[1px] border-[#D9D9D9] ">
          <AccountPasswordForm email={tokenInfo?.email ?? ''} onSubmit={handlePasswordSubmit} />
        </div>
      </div>{' '}
    </div>
  );
};
