import {memo, useState, useMemo, useCallback} from 'react';
import {Helmet} from 'react-helmet';
import {MenuBar} from 'components/MenuBar/MenuBar';
import {PageHeading} from 'components/Connects/PageHeading';
import {
  CommonRegistrationForm,
  FormData,
  FormFieldConfig,
} from 'components/Forms/CommonRegistrationForm/CommonRegistrationForm';
import {submitHubSpotForm} from 'api/api';
import {FreeTrialProgram, parseRelevantParamsFromSearchParams} from 'common/fko-utils';
import Spinner from 'components/Loader/Spinner';
import {useRecaptcha} from 'hooks/useRecaptcha';
import WelcomeBlock from './WelcomeBlock';

type GetFormDefaultValuesFromUrlParamsProps = {
  formFields: FormFieldConfig[];
  urlParams: UrlParams;
};

const getFormDefaultValuesFromUrlParams = ({formFields, urlParams}: GetFormDefaultValuesFromUrlParamsProps) => {
  return formFields.reduce((acc: FormData, field: FormFieldConfig) => {
    if (urlParams[field.name]) {
      acc[field.name] = urlParams[field.name];
    }
    return acc;
  }, {} as FormData);
};

export type UrlParams = {
  [key: string]: string;
};

export type GetFormFieldsProps = {
  locationsAllowed: string[];
  validLocation?: string;
};

export type GetHubspotFormDataForSubmissionProps<T extends UrlParams> = {
  urlParams: T;
  formData: FormData;
  validLocation?: string;
  programName?: FreeTrialProgram;
};

const DEFAULT_MESSAGE = {
  heading: 'Thank You!',
  body: 'Thank you for your interest! A representative from your center will be reaching out to you.',
};

const CONFIRMATION_MESSAGES: Record<FreeTrialProgram, {heading: string; body: string}> = {
  [FreeTrialProgram.DBAT_75_FREE_SWINGS]: {
    heading: '75 Free Swings Claimed!',
    body: 'Check your inbox for a confirmation e-mail with your 75 Free Swings card enclosed. Simply show your confirmation e-mail at your D-BAT center to redeem.',
  },
  [FreeTrialProgram.PICKUP_SOCCER]: DEFAULT_MESSAGE,
  [FreeTrialProgram.LEAGUES_TRIAL]: DEFAULT_MESSAGE,
  [FreeTrialProgram.FTC_DSR]: DEFAULT_MESSAGE,
};

interface CommonRegistrationPageProps {
  programName: FreeTrialProgram;
  locationsAllowed: string[];
  formId: string;
  relevantUrlParams: string[];
  getFormFields: (props: GetFormFieldsProps) => FormFieldConfig[];
  getHubspotFormDataForSubmission: (props: GetHubspotFormDataForSubmissionProps<UrlParams>) => Record<string, string>;
}

const CommonRegistrationPage = memo(
  ({
    programName,
    locationsAllowed,
    formId,
    relevantUrlParams,
    getFormFields,
    getHubspotFormDataForSubmission,
  }: CommonRegistrationPageProps) => {
    const [errorMessage, setErrorMessage] = useState<string>();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const urlParams: UrlParams = useMemo(
      () => parseRelevantParamsFromSearchParams(new URL(document.location.href).searchParams, relevantUrlParams),
      [document.location.href, relevantUrlParams]
    );

    const validLocation = useMemo(
      () =>
        urlParams.locations__drop_down_
          ? locationsAllowed.find(
              (location) => location.toLowerCase() === urlParams.locations__drop_down_?.toLowerCase()
            )
          : undefined,
      [urlParams.locations__drop_down_, locationsAllowed]
    );

    const formFields = useMemo(
      () => getFormFields({validLocation, locationsAllowed}),
      [validLocation, locationsAllowed]
    );

    const defaultValues = useMemo(
      () => getFormDefaultValuesFromUrlParams({formFields, urlParams}),
      [validLocation, formFields]
    );

    const {verifyRecaptchaChallenge} = useRecaptcha();
    const onSubmitHandler = useCallback(
      async (data: FormData) => {
        setIsLoading(true);
        const isRecaptchaVerified = await verifyRecaptchaChallenge();
        if (!isRecaptchaVerified) {
          setErrorMessage('reCAPTCHA challenge failed');
          setIsLoading(false);
          return;
        }

        try {
          const hubSpotData = getHubspotFormDataForSubmission({validLocation, urlParams, formData: data, programName});
          const portalId = process.env.REACT_APP_HUBSPOT_PORTAL_ID ?? '';
          await submitHubSpotForm(formId, portalId, hubSpotData);
          console.log('Form submitted successfully');
          setIsSubmitted(true);
        } catch (error) {
          console.error('Error submitting form:', error);
          setErrorMessage('An error occurred while submitting the form. Please try again.');
        } finally {
          setIsLoading(false);
        }
      },
      [formId, urlParams, validLocation, verifyRecaptchaChallenge]
    );

    const CONFIRMATION_MESSAGE = CONFIRMATION_MESSAGES[programName] || DEFAULT_MESSAGE;

    return (
      <>
        <Helmet>
          <title>{programName}</title>
        </Helmet>

        <div className="grow flex flex-col gap-3 sm:gap-6">
          <MenuBar showHamburger={false} />

          <div className="flex flex-col items-center my-4">
            <PageHeading text={programName} />
          </div>
          <div className="flex flex-col items-center gap-8">
            {isSubmitted ? (
              <div className="max-w-[400px] text-base text-primary font-medium text-center">
                <h2 className="text-xl font-bold mb-4">{CONFIRMATION_MESSAGE.heading}</h2>
                <p>{CONFIRMATION_MESSAGE.body}</p>
              </div>
            ) : (
              <>
                <WelcomeBlock programName={programName} />

                <div className="w-full max-w-[700px]">
                  <div className="relative grow flex flex-col w-full py-8 px-8 bg-white border border-primary/20 rounded shadow-flat-lg mb-12">
                    {isLoading && (
                      <div className="absolute top-0 left-0 h-full w-full grid place-content-center bg-primary bg-opacity-80 rounded pointer-events-none">
                        <Spinner darkMode />
                      </div>
                    )}
                    {validLocation && (
                      <div className="w-full mb-8 text-left">
                        <div className="inline-block border-b border-black border-opacity-10">
                          <div className="text-black text-opacity-80 text-base font-medium py-2 px-2 border-t border-black border-opacity-10">
                            Your center: {validLocation}
                          </div>
                        </div>
                      </div>
                    )}

                    <CommonRegistrationForm
                      ctaButtonText="Submit"
                      onSubmit={onSubmitHandler}
                      errorMessage={errorMessage}
                      fields={formFields}
                      isLoading={isLoading}
                      defaultValues={defaultValues}
                    />
                    <div className="flex justify-center my-[20px]">
                      <p className="text-[10px] text-[#aaa] text-center">
                        This site is protected by reCAPTCHA and the Google{' '}
                        <a
                          className="underline text-[#999]"
                          href="https://policies.google.com/privacy"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Privacy Policy
                        </a>{' '}
                        and{' '}
                        <a
                          className="underline text-[#999]"
                          href="https://policies.google.com/terms"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Terms of Service
                        </a>{' '}
                        apply.
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
);

CommonRegistrationPage.displayName = 'CommonRegistrationPage';

export default CommonRegistrationPage;
