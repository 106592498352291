import {useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {useGetSingleProgram} from 'api/Classes/useGetSingleProgram';
import {getLocationBySiteId} from 'constants/locations';
import {LoadingAnimationFullHeight} from 'components/Loader/LoadingAnimation';
import {MenuBar} from 'components/MenuBar/MenuBar';
import {PageHeading} from 'components/Connects/PageHeading';
import {Button} from 'components/Connects/Button';
import {Breadcrumbs} from 'components/Breadcrumbs/Breadcrumbs';
import {ProgramFilters, useProgramFiltering} from 'components/Programs/ProgramFilters';
import {Modal} from 'components/Modal/Modal';
import {ProgramEmptyDayForm} from 'components/Programs/ProgramEmptyDayForm';
import {ClassSchedule, Enrollment} from 'api/Classes/useGetPrograms';
import {useGetSport} from 'common/useGetSport';
import {useAppSelector} from 'redux/reduxUtils';
import {logAnalyticsEvent} from 'common/analytics-events';
import {ClassScheduleGroupCard} from 'components/Programs/ClassScheduleGroupCard';
import {parseFormattedClassScheduleGroups} from 'utils/programs-utils';

export const ProgramClassSchedulesList = () => {
  const navigate = useNavigate();
  const {siteId, programId} = useParams();
  const {programFiltering} = useProgramFiltering();
  const {basePath, isFreeTrialClasses} = useGetSport();
  const {data: program, isLoading: isLoadingProgram} = useGetSingleProgram({siteId, programId});
  const {userData} = useAppSelector((state) => state.fkoFormDataReducer);

  const [showFilters, setShowFilters] = useState(false);
  const [contactFormData, setContactFormData] = useState<Enrollment | ClassSchedule | null>();

  const partialBookingUrl = `${basePath}/${siteId}/${programId}`;
  const locationName = getLocationBySiteId(siteId ?? '')?.name;
  const pageHeading = isFreeTrialClasses ? 'Free Trial Classes' : program?.name ?? 'Our Classes';

  useEffect(() => {
    logAnalyticsEvent('classes_programs_listing');
    if (!userData) {
      navigate(basePath, {replace: true});
    }
  }, [basePath, navigate, userData]);

  const openContactForm = useCallback(
    ({enrollment}: {enrollment: Enrollment | ClassSchedule | null}) => setContactFormData(enrollment),
    []
  );

  const classScheduleSort: (a: ClassSchedule, b: ClassSchedule) => number = (a, b) =>
    // ages ascending, startTime ascending, name as a fallback
    (a.ages.length && b.ages.length ? (parseInt(a.ages[0]) || 99) - (parseInt(b.ages[0]) || 99) : undefined) ??
    (parseInt(`${a.schedule.startTime?.slice(0, 2)} ${a.schedule.startTime?.slice(3, 5)}`) || 9999) -
      (parseInt(`${b.schedule.startTime?.slice(0, 2)} ${b.schedule.startTime?.slice(3, 5)}`) || 9999) ??
    (a.name && b.name && a.name < b.name ? -1 : 1);

  const classSchedules = useMemo(
    () => program?.classSchedules?.filter(programFiltering).sort(classScheduleSort),
    [program?.classSchedules, programFiltering, classScheduleSort]
  );

  const {classScheduleGroups} = parseFormattedClassScheduleGroups({classSchedules});

  return (
    <>
      <Helmet>
        <title>TOCA Soccer | Program Listings</title>
      </Helmet>

      <Modal isOpen={!!contactFormData} onClose={() => setContactFormData(null)} showHeader>
        <ProgramEmptyDayForm enrollment={contactFormData} />
      </Modal>

      <div className="grow flex flex-col gap-3 sm:gap-6">
        <MenuBar />
        <Breadcrumbs data={{program}} />

        {isLoadingProgram ? (
          <LoadingAnimationFullHeight />
        ) : program ? (
          <>
            <div className="flex justify-center text-center">
              <PageHeading text={pageHeading} />
            </div>

            <div className="flex flex-col gap-6 md:gap-8 lg:mx-auto max-w-[1024px]">
              <p className="font-poppins text-base text-blue-dark text-center mx-auto max-w-[800px]">
                {program.description}
              </p>

              <div className="flex shrink mx-auto md:hidden">
                <Button text="Filters" color="secondary" onClick={() => setShowFilters((prev) => !prev)} />
              </div>

              <div className="grow min-h-full flex flex-col md:flex-row md:gap-8 items-center md:items-start">
                {/* always show Filters on desktop, use showFilters state on mobile */}
                {program && (
                  <>
                    <div className="hidden md:flex w-[208px] min-w-[208px] lg:w-3/12 flex-col">
                      <ProgramFilters program={program} />
                    </div>
                    <div
                      className={`flex md:hidden w-full xs:px-4 sm:px-12 mb-8 flex-col ${
                        showFilters ? 'max-h-[1200px]' : 'max-h-0 overflow-hidden'
                      } transition-all`}
                    >
                      <ProgramFilters program={program} />
                    </div>
                  </>
                )}

                {classScheduleGroups.length ? (
                  <ul className="grow flex flex-col gap-6">
                    {classScheduleGroups.map((classScheduleGroup, i) => (
                      <li key={`enroll_list_card_${i}`} className="">
                        <ClassScheduleGroupCard
                          classScheduleGroup={classScheduleGroup}
                          locationName={locationName}
                          partialBookingUrl={partialBookingUrl}
                          openContactForm={openContactForm}
                        />
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div className="grow grid place-content-center md:mt-3">
                    <p className="text-base text-primary text-center px-5 py-3 rounded border border-grey md:border-primary md:shadow-dispersed md:bg-white">
                      There are no sessions running right now, check back soon!
                    </p>
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <div>
            {/* TODO CLASSES make better */}
            <p>Unable to load program data</p>
          </div>
        )}
      </div>
    </>
  );
};
