import {useState} from 'react';
import {Club} from 'api/Director/club.interface';
import {ReactComponent as EditIcon} from '../../../assets/img/icons/edit-icon-minimal.svg';
import {Modal} from 'components/Modal/Modal';
import {ClubForm} from './ClubForm';

export const ClubView = ({club}: {club: Club}) => {
  const {name, logoUrl} = club;
  const [showEditModal, setShowEditModal] = useState(false);

  return (
    <div className={`flex my-6 items-center w-full justify-start rounded-md`}>
      <img src={logoUrl} alt={name} className="w-20 h-20 rounded-md object-cover" />
      <h2 className={`text-2xl font-bold  ml-4 text-gray-xxdark`}>{name}</h2>
      <EditIcon className="fill-black h-4 w-4 ml-2 mb-1 cursor-pointer" onClick={() => setShowEditModal(true)} />
      {showEditModal && (
        <Modal isOpen={showEditModal} onClose={() => setShowEditModal(false)}>
          <div className="flex flex-col justify-center items-center">
            <h2 className="text-lg font-semibold mb-8  ml-4 text-gray-xxdark">Edit Club</h2>
            <ClubForm club={club} onCancelHandler={() => setShowEditModal(false)} />
          </div>
        </Modal>
      )}
    </div>
  );
};
