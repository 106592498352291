import {useCallback, useEffect, useRef, useState} from 'react';
import {ReactComponent as PlayIcon} from '../../assets/img/icons/play-icon.svg';
import {useSearchParams} from 'react-router-dom';

const MQTT_BROKER_URL = 'ws://localhost:9001';
const TOPIC_STATUS = 'game/status';

enum GAME_STATUS {
  PLAYING = 'PLAYING',
  READY = 'READY',
}

enum GAME_ACTIONS {
  GAME_QUERY = 'GAME_QUERY',
  GAME_PLAY = 'GAME_PLAY',
}

const GameButton = () => {
  const [searchParams] = useSearchParams();
  const [gameState, setGameState] = useState(GAME_STATUS.READY);
  const [error, setError] = useState('');
  const [isConnected, setIsConnected] = useState(false);
  const [mqtt, setMqtt] = useState<any>(null);
  const clientRef = useRef<any>(null);
  const waitingForPlayingStateRef = useRef(false);

  useEffect(() => {
    // Dynamically import mqtt
    import('mqtt')
      .then((mqttModule) => {
        setMqtt(mqttModule.default || mqttModule);
      })
      .catch((err) => {
        setError(`Failed to load MQTT library: ${err.message}`);
      });
  }, []);

  const publishMessage = useCallback((topic: string, message: object) => {
    clientRef.current?.publish(topic, JSON.stringify(message));
  }, []);

  const fetchGameState = useCallback(() => {
    publishMessage(TOPIC_STATUS, {messageType: GAME_ACTIONS.GAME_QUERY});
  }, []);

  const handleError = useCallback(
    (error: Error) => {
      setError(`Error: ${error.message}`);
      setIsConnected(false);
    },
    [setError, setIsConnected]
  );

  useEffect(() => {
    if (!mqtt) {
      // Wait until mqtt is loaded
      return;
    }

    const connectClient = () => {
      const BROKER_URL = searchParams.get('mqtt-url') || MQTT_BROKER_URL;
      if (!BROKER_URL) {
        handleError(new Error('MQTT broker URL is not set'));
        return;
      }

      clientRef.current = mqtt.connect(BROKER_URL, {
        rejectUnauthorized: false,
      });

      clientRef.current.on('connect', () => {
        console.log('Connected to MQTT broker');
        setIsConnected(true);
        clientRef.current.subscribe(TOPIC_STATUS, (err: Error | null) => {
          if (err) {
            handleError(new Error(`Subscription error: ${err.message}`));
          } else {
            fetchGameState();
          }
        });
      });

      clientRef.current.on('error', handleError);
      clientRef.current.on('offline', () => handleError(new Error('MQTT client went offline')));
      clientRef.current.on('message', (topic: string, message: Buffer) => {
        if (topic === TOPIC_STATUS) {
          const status = JSON.parse(message.toString());
          if (status.state) {
            setGameState(status.state);
          }
          if (status.state === GAME_STATUS.PLAYING) {
            waitingForPlayingStateRef.current = false;
          }
        }
      });
    };

    connectClient();

    return () => {
      console.log('Disconnecting MQTT client');
      clientRef.current?.unsubscribe(TOPIC_STATUS, () => {
        clientRef.current?.end();
      });
    };
  }, [mqtt, fetchGameState]);

  const handlePlay = useCallback(() => {
    publishMessage(TOPIC_STATUS, {messageType: GAME_ACTIONS.GAME_PLAY});
    setGameState(GAME_STATUS.PLAYING);
    waitingForPlayingStateRef.current = true;
    setTimeout(() => {
      if (waitingForPlayingStateRef.current) {
        fetchGameState();
      }
    }, 2000);
  }, [fetchGameState]);

  return (
    <div className="flex items-center justify-center h-screen bg-[#f4f4f4] exceed-global-padding">
      {mqtt ? (
        isConnected ? (
          gameState === GAME_STATUS.READY ? (
            <div className="flex flex-col items-center justify-center gap-4">
              <div
                className="w-40 h-40 bg-primary rounded-full flex items-center justify-center cursor-pointer"
                onClick={handlePlay}
              >
                <PlayIcon className="w-20 h-20 ml-2 text-white" />
              </div>
              <div className="text-2xl font-semibold text-primary">PLAY</div>
            </div>
          ) : (
            <div className="text-3xl font-semibold text-primary">GAME IN PROGRESS</div>
          )
        ) : (
          <div className="text-2xl font-semibold">{error}</div>
        )
      ) : (
        <div className="text-2xl font-semibold">Loading...</div>
      )}
    </div>
  );
};

GameButton.displayName = 'GameButton';

export default GameButton;
