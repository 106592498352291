import {useNavigate} from 'react-router-dom';
import {useProfiles} from 'api/User/useProfiles';
import {useLogout} from 'hooks/useLogout';
import {useGetDirectorInfo} from 'api/Director/useGetDirectorInfo';
import {roleType} from 'constants/constants';
import {TeamsView} from 'pages/Coach/CoachTeams/Teams.view';
import {LogoBanner} from 'components/Connects/LogoBanner';
import {PageContainer} from 'components/PageContainer/RevisedPageContainer';
import Spinner from 'components/Loader/Spinner';
import {ClubView} from 'pages/Director/Club/Club.view';

export const DirectorTeams = () => {
  const navigate = useNavigate();
  const {currentProfile} = useProfiles();
  const {data: directorInfo, isLoading} = useGetDirectorInfo(currentProfile?._id);
  const {logout} = useLogout();

  const teams = directorInfo?.teams;
  const club = directorInfo?.club ?? undefined;

  const onTeamSelect = (teamId: string) => {
    navigate(`teams/${teamId}`);
  };

  return (
    <PageContainer>
      <LogoBanner />
      {isLoading ? (
        <div className="w-full h-screen flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <>
          {club && <ClubView club={club} />}
          <TeamsView
            onTeamSelect={onTeamSelect}
            teams={teams}
            logout={logout}
            loading={isLoading}
            role={roleType.director}
            club={club}
          />
        </>
      )}
    </PageContainer>
  );
};
