import {useQuery} from '@tanstack/react-query';
import {validatePlayerTokenAndGetPlayerInfo} from 'api/api';
import {PlayerTokenInfo} from 'pages/ExistingPlayerOnboarding/types';

export const useValidatePlayerTokenAndGetPlayerInfo = ({token}: {token: string}) => {
  return useQuery<PlayerTokenInfo>({
    queryKey: ['validate-player-token', token],
    queryFn: () => validatePlayerTokenAndGetPlayerInfo(token),
    enabled: !!token,
  });
};
