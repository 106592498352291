import React from 'react';
import {FormSelect, FormOptionGroup} from '../FormSelect/FormSelect';
import {useGetLocationFormOptionGroups} from 'api/FKO/useGetLocationFormOptionGroups';
import {useGetSport} from 'common/useGetSport';
import {isLocationPermitted} from '../../../feature-flags/fko-locations';
import {UseFormRegister, UseFormSetValue} from 'react-hook-form';
import {FormData} from '../CommonRegistrationForm/CommonRegistrationForm';

enum SPORT_PLACEHOLDER_VALUE {
  DBAT = 'D-BAT Training Center',
  DBAT_75_SWINGS = 'D-BAT 75 Free Swings Center',
  TOCA = 'TOCA Training Center',
}

type LocationFormSelectProps = {
  register: UseFormRegister<FormData>;
  hasError: boolean;
  setValue: UseFormSetValue<FormData>;
};

export const LocationFormSelect: React.FC<LocationFormSelectProps> = ({register, hasError, setValue}) => {
  const {
    isDbat,
    isPlayerAssessment,
    isClasses,
    isFreeTrialClasses,
    isDbat75Swings,
    isFtcDsr,
    isFreeTrialPickupSoccer,
    isFreeTrialLeagues,
  } = useGetSport();
  const {locationFormOptionGroups} = useGetLocationFormOptionGroups();

  const getRestrictedLocationFormOptionGroups = () => {
    return locationFormOptionGroups
      .map((optionGroup) => {
        const allowedOptions = optionGroup.options.filter((opt) =>
          isLocationPermitted({
            locationId: opt.id,
            isClasses,
            isFreeTrialClasses,
            isDbat,
            isPlayerAssessment,
            isDbat75Swings,
            isFtcDsr,
            isFreeTrialPickupSoccer,
            isFreeTrialLeagues,
          })
        );
        if (!allowedOptions.length) {
          return null;
        }
        return {...optionGroup, options: allowedOptions};
      })
      .filter((optionGroup) => !!optionGroup);
  };

  const restrictedLocationFormOptionGroups = getRestrictedLocationFormOptionGroups();

  return (
    <FormSelect
      {...register('location')}
      id="location"
      optionGroups={restrictedLocationFormOptionGroups as FormOptionGroup[]}
      placeholderValue={
        isDbat75Swings
          ? SPORT_PLACEHOLDER_VALUE.DBAT_75_SWINGS
          : isDbat
          ? SPORT_PLACEHOLDER_VALUE.DBAT
          : SPORT_PLACEHOLDER_VALUE.TOCA
      }
      defaultValue="disabled-placeholder"
      hasError={hasError}
      onChange={(e) => setValue('location', e.currentTarget.value, {shouldValidate: true})}
    />
  );
};
