import {useState} from 'react';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {toast} from 'react-toastify';
import {useForm} from 'react-hook-form';
import {
  addCoachToTeam,
  assignExistingCoachToTeam,
  createAccountAndProfile,
  getProfileByEmail,
  inviteUserToTeam,
} from 'api/api';
import {Modal} from 'components/Modal/Modal';
import {Button} from 'components/Connects/Button';
import {FormSelect} from 'components/Forms/FormSelect/FormSelect';
import {FormInput} from 'components/Forms/FormInput/FormInput';

interface AddCoachProps {
  showModal: boolean;
  cancelModal: () => void;
  teamId: string;
  siteId: string;
}

enum CoachAction {
  CreateNewCoach = 'create-new-coach',
  AssignExistingCoach = 'assign-existing-coach',
  InviteCoach = 'invite-coach',
}

export interface AddCoachFormData {
  selectedAction: CoachAction;
  email: string;
  password: string;
  coachType: string;
  firstName: string;
  lastName: string;
}

const coachTypeOptions = [
  {
    id: 1,
    displayValue: 'Head Coach',
    value: 'head-coach',
  },
  {
    id: 2,
    displayValue: 'Assistant Coach',
    value: 'assistant-coach',
  },
];

/**
 * When a new user is created and initialized, new user profiles are generated through the onCreateUser action
 * when a Firebase user record is added. Due to the potential delay between the creation of the Firebase record
 * and the corresponding database entry, it may be necessary to retry the process several times until either
 * a result is obtained or the maximum number of attempts is reached.
 **/
const pollForCreatedProfile = async (email: string) => {
  for (let attempt = 0; attempt < 20; attempt++) {
    try {
      const profile = await getProfileByEmail(email);
      if (profile) {
        return profile;
      }
    } catch (error: any) {
      if (error?.response?.status === 404) {
        // Ignore the 404 error and continue polling
      } else {
        // Re-throw the error if it's not a 404
        throw error;
      }
    }

    await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1000ms before next attempt
  }
  throw new Error('Profile record not found');
};

/**
 * Represents a modal component for adding a coach to team.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.showModal - Determines whether the modal is visible or not.
 * @param {Function} props.cancelModal - The function to cancel and close the modal.
 * @returns {JSX.Element} The JSX element representing the add coach modal.
 */
export const AddCoach = ({showModal, cancelModal, teamId, siteId}: AddCoachProps) => {
  const queryClient = useQueryClient();
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: {errors},
    watch,
  } = useForm<AddCoachFormData>({defaultValues: {selectedAction: CoachAction.InviteCoach}});

  const watchSelectedAction = watch('selectedAction');

  const createPlayerAppFirebaseUserMutation = useMutation({
    mutationFn: createAccountAndProfile,
  });

  const addCoachToTeamMutation = useMutation({
    mutationFn: addCoachToTeam,
  });

  const assignExistingCoachToTeamMutation = useMutation({
    mutationFn: assignExistingCoachToTeam,
  });

  const inviteUserToTeamMutation = useMutation({
    mutationFn: inviteUserToTeam,
  });

  const pollForProfileCreated = useMutation({
    mutationFn: pollForCreatedProfile,
  });

  const isLoading =
    createPlayerAppFirebaseUserMutation.isLoading ||
    addCoachToTeamMutation.isLoading ||
    assignExistingCoachToTeamMutation.isLoading ||
    pollForProfileCreated.isLoading ||
    inviteUserToTeamMutation.isLoading;

  const addCoachHandler = async (data: AddCoachFormData) => {
    let {email, firstName, lastName} = data;
    const {password, selectedAction, coachType} = data;

    if (!coachType) {
      setErrorMsg('Coach Type is required');
      return;
    }

    email = email?.trim()?.toLowerCase();
    firstName = firstName?.trim();
    lastName = lastName?.trim();
    let toastMessage = '';

    try {
      if (selectedAction === CoachAction.CreateNewCoach) {
        await createPlayerAppFirebaseUserMutation.mutateAsync({email, password, firstName, lastName});
        // Add coach to team
        await addCoachToTeamMutation.mutateAsync({
          newCoachData: {firstName, lastName, coachType, email},
          teamId,
        });
        toastMessage = 'Successfully created coach';
      } else if (selectedAction === CoachAction.AssignExistingCoach) {
        await assignExistingCoachToTeamMutation.mutateAsync({
          coachData: {email, coachType},
          teamId,
        });
        toastMessage = 'Successfully assigned existing coach';
      } else {
        // Logic for invite coach (email + coachType)
        await inviteUserToTeamMutation.mutateAsync({email, role: ['coach'], teamId, siteId, profileData: {coachType}});
        toastMessage = 'Successfully invited coach';
      }
      queryClient.invalidateQueries(['director-info']);
      queryClient.invalidateQueries(['team-coaches', teamId]);
      toast.success(toastMessage);
      setErrorMsg('');
      reset();
      cancelModal();
    } catch (error: any) {
      const errMessage =
        error?.response?.data?.message || error?.response?.data?.error || 'Failed to add coach to team';
      setErrorMsg(errMessage);
    }
  };

  const onClickCancelHandler = () => {
    reset();
    setErrorMsg('');
    cancelModal();
    createPlayerAppFirebaseUserMutation.reset();
    addCoachToTeamMutation.reset();
  };

  return (
    <Modal isOpen={showModal} onClose={cancelModal} showHeader={true} title="Add Coach" modalTestId="add-coach-modal">
      <form className="flex flex-col justify-center items-center" onSubmit={handleSubmit(addCoachHandler)}>
        <div className=" w-full flex flex-col justify-center ml-2 mb-4">
          <label className="mb-1 text-base">
            <input
              {...register('selectedAction')}
              name="selectedAction"
              type="radio"
              value={CoachAction.InviteCoach}
              className="radio radio-primary mr-2"
            />
            Invite a new coach
          </label>
          <label className="mb-1 text-base">
            <input
              {...register('selectedAction')}
              name="selectedAction"
              type="radio"
              value={CoachAction.CreateNewCoach}
              className="radio radio-primary mr-2"
            />
            Create a new coach
          </label>
          <label className="mb-1 text-base">
            <input
              {...register('selectedAction')}
              name="selectedAction"
              type="radio"
              value={CoachAction.AssignExistingCoach}
              className="radio radio-primary mr-2"
            />
            Assign an existing coach
          </label>
        </div>

        {watchSelectedAction === CoachAction.CreateNewCoach && (
          <>
            <FormInput
              {...register('firstName', {required: 'First Name is required'})}
              id="firstName"
              hasError={!!errors.firstName?.message}
              placeholder="First Name"
              type="text"
            />
            <p className="text-xs text-alert mb-1">{errors.firstName?.message ?? '\u00A0'}</p>
          </>
        )}

        {watchSelectedAction === CoachAction.CreateNewCoach && (
          <>
            <FormInput
              {...register('lastName', {required: 'Last Name is required'})}
              id="lastName"
              hasError={!!errors.lastName?.message}
              placeholder="Last Name"
              type="text"
            />
            <p className="text-xs text-alert mb-1">{errors.lastName?.message ?? '\u00A0'}</p>
          </>
        )}

        <FormInput
          {...register('email', {required: 'Email is required'})}
          id="email"
          hasError={!!errors.email?.message}
          placeholder="Email"
          type="text"
        />
        <p className="text-xs text-alert mb-1">{errors.email?.message ?? '\u00A0'}</p>

        {watchSelectedAction === CoachAction.CreateNewCoach && (
          <>
            <FormInput
              {...register('password', {required: 'Password is required'})}
              id="password"
              hasError={!!errors.password?.message}
              placeholder="Password"
              type="text"
            />
            <p className="text-xs text-alert mb-1">{errors.password?.message ?? '\u00A0'}</p>
          </>
        )}

        <FormSelect
          {...register('coachType', {
            required: 'Coach Type is required',
            validate: (value) => value !== 'disabled-placeholder' || 'Coach Type is required',
          })}
          placeholderValue="Select Coach Type"
          defaultValue={'disabled-placeholder'}
          hasError={!!errors.coachType}
          options={coachTypeOptions}
          onChange={(e) => setValue('coachType', e.currentTarget.value, {shouldValidate: true})}
        />
        <p className="text-xs text-alert mb-1">{errors.coachType?.message ?? '\u00A0'}</p>

        {errorMsg && (
          <p data-test-id="add-coach-alert" className="text-xs text-alert mb-1 mt-2">
            {errorMsg || 'Failed to add coach to team'}
          </p>
        )}

        <div className="flex items-center justify-center mt-4">
          <Button text="Save" color="secondary" variant="cta" disabled={isLoading} />
          <Button className="ml-4" text="Cancel" color="primary" variant="standard" onClick={onClickCancelHandler} />
        </div>
      </form>
    </Modal>
  );
};
