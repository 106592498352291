import {useGetSport} from 'common/useGetSport';
import {FormSectionHeading} from 'components/Forms/FormSectionHeading/FormSectionHeading';

const fkoListItems = [
  'Check-in at the front desk when you arrive and be greeted by your trainer',
  'Your trainer will ask about your soccer goals and talk through your Baseline Session',
  'During the Baseline Session, your trainer will work with you 1:1 to identify strengths and your most important opportunities for improvement',
  'Afterwards, you’ll be presented with (no-commitment) options for ongoing personalized training to help you reach full potential',
];

const ftcListItems = [
  'Check in at the front desk when you arrive and you and your player will be led to class by one of our expert team members',
  `Dedicated coaches will lead interactive games and fun challenges suited to your player’s age and skill level`,
  `Afterwards, you’ll walk through class schedules and find the class that works best!`,
];

const SessionExpectationContent = () => {
  const {isFreeBaselineSession, isFreeTrialClasses} = useGetSport();

  if (!isFreeBaselineSession && !isFreeTrialClasses) {
    return null;
  }

  const listItems = isFreeTrialClasses ? ftcListItems : fkoListItems;

  const content = (
    <div className="py-4 flex flex-col items-center">
      {isFreeTrialClasses ? (
        <p className="text-lg font-semibold">What to Expect</p>
      ) : (
        <FormSectionHeading text="What to Expect:" />
      )}
      <ul className="list-disc pl-5 text-sm mt-4">
        {listItems.map((item, index) => (
          <li key={index} className="my-2">
            {item}
          </li>
        ))}
      </ul>
    </div>
  );

  return content;
};

export default SessionExpectationContent;
