import {ClassSchedule, Enrollment} from 'api/Classes/useGetPrograms';
import moment from 'moment';
import classPlaceholder from '../assets/img/groupClass.jpg';
import imageOverrideAges1_2 from '../assets/img/ages1-2.jpeg';
import imageOverrideAges3_4 from '../assets/img/ages3-4.jpeg';
import imageOverrideAges5_6 from '../assets/img/ages5-6.jpeg';
import imageOverrideAges7_8 from '../assets/img/ages7-8.jpeg';
import imageOverrideAges9_10 from '../assets/img/ages9-10.jpeg';
import imageOverrideAges11_13 from '../assets/img/ages11-13.jpeg';

// TODO CLASSES host images properly and remove hacky overrides
export const selectOverrideImage = ({enrollmentName}: {enrollmentName?: string}) => {
  return enrollmentName?.toLowerCase().includes('1-2')
    ? imageOverrideAges1_2
    : enrollmentName?.toLowerCase().includes('3-4')
    ? imageOverrideAges3_4
    : enrollmentName?.toLowerCase().includes('5-6')
    ? imageOverrideAges5_6
    : enrollmentName?.toLowerCase().includes('7-8')
    ? imageOverrideAges7_8
    : enrollmentName?.toLowerCase().includes('9-10')
    ? imageOverrideAges9_10
    : enrollmentName?.toLowerCase().includes('11-13')
    ? imageOverrideAges11_13
    : classPlaceholder;
};

export const ALLOWED_PROGRAM_NAMES = ['Soccer Classes'];

export const classScheduleAgeMatches = ({classSchedule, age}: {classSchedule: ClassSchedule; age?: number}) => {
  const permittedAges = classSchedule?.ages?.map((age) => Number(age));

  if (!permittedAges || !age) {
    return true; // if 'Age [x]' not found, classSchedule passes
  } else if (permittedAges.length === 1 && age === Number(permittedAges[0])) {
    return true; // 'Age [x]' will have 'length === 1' and age must match exactly
  } else if (permittedAges.length > 1 && age >= Number(permittedAges[0]) && age <= Number(permittedAges[1])) {
    return true; // 'Age [x]-[x] will have 'length === 2'
  } else {
    return false; // if none of the above are true, age is outside specified range
  }
};

// TODO CLASSES naming
export const enrollmentAgeMatches = ({enrollment, age}: {enrollment: Enrollment | ClassSchedule; age?: number}) => {
  const reg = /(\d+(-\d+)?)/; // TODO establish reliable pattern with product
  const val = reg.exec(enrollment.name ?? '')?.[1]; // TODO confirm...
  const nums = val?.split('-');

  if (!nums || !age) {
    return true; // if 'Age [x]' not found, enrollment passes
  } else if (nums.length === 1 && age === Number(nums[0])) {
    return true; // 'Age [x]' will have 'length === 1' and age must match exactly
  } else if (nums.length > 1 && age >= Number(nums[0]) && age <= Number(nums[1])) {
    return true; // 'Age [x]-[x] will have 'length === 2'
  } else {
    return false; // if none of the above are true, age is outside specified range
  }
};

export const isEnrollable = (enrollment: Enrollment, date: moment.Moment) => {
  const enrollmentHasAlreadyStarted = moment(enrollment.schedule.startDate).startOf('day').isSameOrBefore(date);
  return enrollmentHasAlreadyStarted ? (enrollment.schedule.allowOpenEnrollment ? true : false) : true;
};

export const reformatWeekdayAbrv = (day: string) => {
  switch (day) {
    case 'U':
      return 'Sunday';
    case 'M':
      return 'Monday';
    case 'T':
      return 'Tuesday';
    case 'W':
      return 'Wednesday';
    case 'R':
      return 'Thursday';
    case 'F':
      return 'Friday';
    case 'S':
      return 'Saturday';
    case 'Sunday':
      return 'U';
    case 'Monday':
      return 'M';
    case 'Tuesday':
      return 'T';
    case 'Wednesday':
      return 'W';
    case 'Thursday':
      return 'R';
    case 'Friday':
      return 'F';
    case 'Saturday':
      return 'S';
    default:
      return 'undefined';
  }
};

export const formatTimeVaguely = ({dateTime, timeString}: {dateTime?: Date; timeString?: string}) => {
  const time = dateTime ? Number(moment(dateTime).format('H')) : Number(timeString?.slice(0, 2));
  return time >= 17 ? 'Evening' : time < 12 ? 'Morning' : 'Afternoon';
};

export const formatWeekdaysArrayForDisplay = (daysArray: string[]) => {
  return daysArray.length <= 3
    ? daysArray.map((day) => day + 's').join(', ')
    : daysArray.map((day) => day.slice(0, 3)).join(', ');
};

export const format24hrTimeString = ({
  time,
  includeTimePeriod = true,
}: {
  time?: string;
  includeTimePeriod?: boolean;
}) => {
  if (!time) {
    return '??:??';
  }
  let output;
  const minutes = time.slice(3, 5);
  const hours24 = Number(time.slice(0, 2));

  if (hours24 === 0 || hours24 === 12) {
    output = `12:${minutes}`;
  } else if (hours24 < 12) {
    output = `${hours24}:${minutes}`;
  } else {
    output = `${hours24 - 12}:${minutes}`;
  }

  if (includeTimePeriod) {
    return output + (hours24 - 12 >= 0 ? 'pm' : 'am');
  }
  return output;
};

export const getClassDisplayName = (name?: string) => {
  // TODO Review as this might not be future-proof
  return /TOCA Class \d\d?(-\d\d?)?/.test(name ?? '')
    ? name?.replace('TOCA Class', 'TOCA Class Age') ?? ''
    : name ?? '';
};

//
//
//
export type ClassScheduleGroup = {
  displayName: string;
  primaryImage: string;
  tags?: ClassSchedule['tags'];
  classSchedules: FormattedClassSchedule[];
  // schedules: Array<{
  //   startTime: string;
  //   vagueTime: string;
  //   availableCount: number;
  // }>;
};

export type FormattedClassSchedule = ClassSchedule & {
  displayName: string;
  primaryImage: string;
  startTime: string;
  vagueTime: 'Evening' | 'Morning' | 'Afternoon' | 'TimeError';
  availableCount: number;
};

export const parseFormattedClassScheduleGroups = ({
  classSchedules,
}: {
  classSchedules?: ClassSchedule[];
}): {classScheduleGroups: ClassScheduleGroup[]} => {
  if (!classSchedules?.length) {
    return {classScheduleGroups: []};
  }

  const formatClassScheduleForDisplay = ({classSchedule}: {classSchedule: ClassSchedule}): FormattedClassSchedule => {
    const displayName = getClassDisplayName(classSchedule?.name);
    const primaryImage = selectOverrideImage({enrollmentName: classSchedule?.name});
    const startTime = format24hrTimeString({time: classSchedule.schedule.startTime});
    const vagueTime = startTime ? formatTimeVaguely({timeString: classSchedule.schedule.startTime}) : 'TimeError';
    const availableCount = classSchedule.capacity?.available;
    return {
      ...classSchedule,
      displayName,
      primaryImage,
      startTime,
      vagueTime,
      availableCount,
    };
  };

  const classSchedulesByName: {[name: string]: FormattedClassSchedule[]} = {};
  for (const classSchedule of classSchedules) {
    const _name = getClassDisplayName(classSchedule.name);
    classSchedulesByName[_name] ||= [];
    classSchedulesByName[_name].push(formatClassScheduleForDisplay({classSchedule}));
  }

  const classScheduleGroups: ClassScheduleGroup[] = [];
  for (const [classScheduleName, classSchedules] of Object.entries(classSchedulesByName)) {
    classScheduleGroups.push({
      displayName: classScheduleName,
      primaryImage: classSchedules[0].primaryImage,
      classSchedules,
    });
  }

  return {
    classScheduleGroups: classScheduleGroups.sort((a, b) =>
      a.displayName && b.displayName && a.displayName < b.displayName ? -1 : 1
    ),
  };
};

export const parseClassAndClassScheduleIds = ({combinedIds}: {combinedIds?: string | null}) => {
  const [classScheduleId, classId] = combinedIds ? combinedIds.split('--') : [null, null];
  return {classScheduleId, classId};
};

export const combineClassAndClassScheduleIds = ({
  classScheduleId,
  classId,
}: {
  classScheduleId?: string | null;
  classId?: string | null;
}) => (classScheduleId && classId ? `${classScheduleId}--${classId}` : null);
