import {useState, useCallback, useEffect} from 'react';
import {RecaptchaVerifier, signInWithPhoneNumber} from 'firebase/auth';
import {auth as firebaseAuth} from '../firebase/config';

declare const grecaptcha: any;

interface PhoneVerificationState {
  phoneNumber: string;
  verificationId: string;
  verificationCode: string;
  showVerificationInput: boolean;
  error: string;
  isSubmitting: boolean;
}

const initialState: PhoneVerificationState = {
  phoneNumber: '',
  verificationId: '',
  verificationCode: '',
  showVerificationInput: false,
  error: '',
  isSubmitting: false,
};

type FirebaseErrorCode =
  | 'auth/invalid-phone-number'
  | 'auth/invalid-app-credential'
  | 'auth/internal-error'
  | 'auth/too-many-requests'
  | 'auth/network-request-failed';

const ERROR_MESSAGES: Record<FirebaseErrorCode, string> = {
  'auth/invalid-phone-number': 'Invalid phone number format',
  'auth/invalid-app-credential': 'User credentials are invalid. Please try again.',
  'auth/internal-error': 'An error occurred. Please refresh the page and try again.',
  'auth/too-many-requests': 'Too many attempts. Please try again later.',
  'auth/network-request-failed': 'Network error. Please check your connection and try again.',
};

export const usePhoneVerification = () => {
  const [state, setState] = useState<PhoneVerificationState>(initialState);
  const [recaptchaVerifier, setRecaptchaVerifier] = useState<RecaptchaVerifier | null>(null);

  const resetForm = useCallback(() => {
    setState(initialState);
    if (recaptchaVerifier) {
      recaptchaVerifier.clear();
      setRecaptchaVerifier(null);
    }
  }, [recaptchaVerifier]);

  const createRecaptchaVerifier = useCallback(() => {
    try {
      if (recaptchaVerifier) {
        recaptchaVerifier.clear();
        setRecaptchaVerifier(null);
      }

      const container = document.getElementById('recaptcha-container');
      if (!container) {
        console.error('reCAPTCHA container not found');
        return null;
      }

      const verifier = new RecaptchaVerifier(firebaseAuth, 'recaptcha-container', {
        size: 'invisible',
        callback: () => {
          console.log('reCAPTCHA verified');
        },
        'expired-callback': () => {
          resetForm();
          setState((prev) => ({...prev, error: 'reCAPTCHA expired. Please try again.'}));
        },
      });

      verifier.render();
      setRecaptchaVerifier(verifier);
      return verifier;
    } catch (error) {
      console.error('Error creating reCAPTCHA verifier:', error);
      return null;
    }
  }, [recaptchaVerifier, resetForm]);

  const handleAuthError = useCallback(
    (error: any) => {
      const errorCode = error.code as FirebaseErrorCode;
      const errorMessage = ERROR_MESSAGES[errorCode] || 'Something went wrong. Please try again.';

      setState((prev) => ({...prev, error: errorMessage}));

      if (recaptchaVerifier) {
        recaptchaVerifier.render().then(function (widgetId) {
          grecaptcha.reset(widgetId);
        });
      }

      if (errorCode === 'auth/internal-error') {
        console.error('Internal error details:', error);
      }
    },
    [recaptchaVerifier, setState]
  );

  const handleVerificationError = useCallback(
    (error: any) => {
      if (error.code === 'auth/invalid-verification-code') {
        setState((prev) => ({...prev, error: 'Invalid verification code. Please try again.'}));
      } else if (error.code === 'auth/code-expired') {
        setState((prev) => ({
          ...prev,
          error: 'Verification code has expired. Please request a new one.',
          showVerificationInput: false,
        }));
      } else {
        handleAuthError(error);
      }
    },
    [handleAuthError]
  );

  const handlePhoneVerification = useCallback(
    async (phoneNumber: string) => {
      try {
        setState((prev) => ({...prev, isSubmitting: true, error: ''}));

        const verifier = createRecaptchaVerifier();
        if (!verifier) {
          throw new Error('Failed to initialize reCAPTCHA');
        }

        // // Wait for 1 second before verifying the reCAPTCHA
        // await new Promise((resolve) => setTimeout(resolve, 1000));
        await verifier.verify();

        const confirmationResult = await signInWithPhoneNumber(firebaseAuth, phoneNumber, verifier);

        setState((prev) => ({
          ...prev,
          verificationId: confirmationResult.verificationId,
          showVerificationInput: true,
          phoneNumber,
          error: '',
        }));
      } catch (error: any) {
        resetForm();
        handleAuthError(error);
      } finally {
        setState((prev) => ({...prev, isSubmitting: false}));
      }
    },
    [createRecaptchaVerifier, resetForm, handleAuthError]
  );

  const clearError = useCallback(() => {
    setState((prev) => ({...prev, error: ''}));
  }, []);

  useEffect(() => {
    return () => {
      if (recaptchaVerifier) {
        recaptchaVerifier.clear();
      }
    };
  }, [recaptchaVerifier]);

  return {
    state,
    setState,
    handlePhoneVerification,
    resetForm,
    handleVerificationError,
    clearError,
  };
};
