import {memo, ReactNode} from 'react';
import {TocaLogo} from '../FkoLogos/TocaLogo';
import {DbatLogo} from '../FkoLogos/DbatLogo';
import {useGetSport} from 'common/useGetSport';
import {FKOProgressStepper} from 'components/FKO/FKOProgressStepper/FKOProgressStepper';

type FormHeadingProps = {
  heading?: string | ReactNode;
  subheading?: string | ReactNode;
  isConfirmation?: boolean;
};

export const FormHeading = memo(({heading, subheading, isConfirmation = false}: FormHeadingProps) => {
  const {isDbat} = useGetSport();

  return (
    <div
      className={`flex flex-col items-center gap-1 mb-6 ${isConfirmation ? '' : 'mt-[-16px] exceed-classic-padding'}`}
    >
      {isDbat ? <DbatLogo withBackground={!isConfirmation} /> : <TocaLogo />}
      {heading && <h1 className="font-helvetica text-black text-center text-[26px] font-light">{heading}</h1>}
      {subheading && (
        <h2 className="font-poppins text-black-faded text-center text-base font-light [&>a]:text-fko-link-color [&>a]:cursor-pointer [&>a]:hover:underline">
          {subheading}
        </h2>
      )}
      {!isConfirmation && <FKOProgressStepper />}
    </div>
  );
});
FormHeading.displayName = 'FormHeading';
