import {memo} from 'react';
import CommonRegistrationPage from 'components/Forms/CommonRegistrationForm/CommonRegistrationPage';
import {getDbat75FormDataForSubmission, getDbat75FormFields} from 'common/dbat75Utils';
import {FreeTrialProgram, relevantDbat75UrlParams} from 'common/fko-utils';
import {tocaLocations, tocaLocationsWithoutWorkingMboSiteId} from 'constants/locations';
import {isDevelopmentEnvironment} from 'common/isTestEnvironment';

const locationsAllowed = [...tocaLocations, ...tocaLocationsWithoutWorkingMboSiteId]
  .filter((loc) => loc.dbat75SwingsSupported)
  .map((loc) => loc.hubspotName);

const Dbat75FreeSwingClaimRegistrationPage = memo(() => {
  return (
    <CommonRegistrationPage
      programName={FreeTrialProgram.DBAT_75_FREE_SWINGS}
      locationsAllowed={locationsAllowed}
      formId={process.env.REACT_APP_HUBSPOT_DBAT_75_FREE_SWINGS_FORM_ID ?? ''}
      relevantUrlParams={relevantDbat75UrlParams}
      getFormFields={getDbat75FormFields}
      getHubspotFormDataForSubmission={getDbat75FormDataForSubmission}
    />
  );
});

Dbat75FreeSwingClaimRegistrationPage.displayName = 'Dbat75FreeSwingClaimRegistrationPage';

export default Dbat75FreeSwingClaimRegistrationPage;
