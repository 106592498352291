import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {auth as fireBaseAuth} from '../../firebase/config';
import {createUserWithEmailAndPassword, sendEmailVerification} from 'firebase/auth';
import {setAccessToken, setCurrentAccountId} from 'user/user-utils';
import {ValidatedOnboardingData} from './types';
import tocaNewLogo from '../../assets/img/my-toca-new-logo.svg';
import styles from './NewUserOnboarding.module.scss';
import {
  NewUserOnboardingRegistrationForm,
  NewUserOnboardingRegistrationFormSubmission,
} from './NewUserOnboardingRegistrationForm';
import {useUpdateOnboardingProfile} from '../../api/User/useUpdateOnboardingProfile';
import {createInitialProfile} from '../../api/api';

export const ContentRegistrationForm = ({tokenInfo, token, content}: ValidatedOnboardingData) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const {updateOnboardingProfile} = useUpdateOnboardingProfile();

  const onSubmitHandler = async (data: NewUserOnboardingRegistrationFormSubmission) => {
    setLoading(true);
    try {
      const {user} = await createUserWithEmailAndPassword(fireBaseAuth, tokenInfo.email, data.password);
      setCurrentAccountId(user.uid);
      setAccessToken((await user.getIdTokenResult()).token);
      if (!fireBaseAuth.currentUser.emailVerified) {
        await sendEmailVerification(fireBaseAuth.currentUser);
      }
      await createInitialProfile({firstName: data.firstName, lastName: data.lastName, email: tokenInfo.email});
      await updateOnboardingProfile({
        token,
        update: {
          firstName: data.firstName,
          lastName: data.lastName,
        },
      });
      setLoading(false);
      navigate(user.emailVerified ? '/home' : '/verify-email');
    } catch (error: any) {
      console.log('Error submitting form', error);
      let errorMsg = 'Sign up failed';
      if (error.code === 'auth/email-already-in-use') {
        errorMsg = 'This email address is already in use';
      }
      setErrorMessage(errorMsg);
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col justify-between min-h-screen overflow-y-scroll items-center ">
      <img className="my-10" src={tocaNewLogo} height={12} width={80} alt="TOCA Logo" />

      {/* Team Branding */}
      <div className="flex flex-col items-center px-20">
        {content.logoUrl && (
          <img
            className="w-24 h-24 object-cover rounded-md border border-grey mt-10"
            src={content.logoUrl}
            alt="Team Logo"
          />
        )}
        <div className="text-white text-base font-semibold mt-2">{content.heading}</div>
        <div className="text-xs text-white text-center mt-2">{content.subtitle}</div>
      </div>

      {/* Registration Form */}
      <div className={`flex flex-1 flex-col justify-end w-full`}>
        <div className={`pl-5 pr-5 w-full relative ${styles.halfTransparentHalfWhite}`}>
          <div className="rounded-md p-5 mt-8 bg-white relative z-20">
            <div className="text-primary font-semibold text-lg text-center mb-4">
              {content.registrationHeading.toUpperCase()}
            </div>
            <NewUserOnboardingRegistrationForm
              isLoading={loading}
              onSubmitHandler={onSubmitHandler}
              errorMessage={errorMessage}
              defaultEmail={tokenInfo.email}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
