import {Helmet} from 'react-helmet';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useCallback, useState} from 'react';
import {sendFkoHubspotData} from 'common/fko-utils';
import {IS_TEST_ENVIRONMENT} from 'common/isTestEnvironment';
import {
  HUBSPOT_LOCATION_PARAM,
  HUBSPOT_USER_PHONE_PARAM,
  HUBSPOT_USER_LAST_NAME_PARAM,
  HUBSPOT_USER_FIRST_NAME_PARAM,
  locations,
  HUBSPOT_USER_EMAIL_PARAM,
} from '../utils';
import {getLocationByHubspotName, getLocationBySiteId} from 'constants/locations';
import {TocaLogo} from 'components/Forms/FkoLogos/TocaLogo';
import {PlayerContactDetailsForm, PlayerContactDetailsFormSubmission} from './PlayerContactDetailsForm';
import {useRecaptcha} from 'hooks/useRecaptcha';

const ERROR_MESSAGES = {
  RECAPTCHA_FAILED: 'reCAPTCHA challenge failed',
  GENERIC_ERROR: 'Sorry! Something went wrong.',
};

const PlayerContactDetails = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const locationHubspotName = searchParams.get(HUBSPOT_LOCATION_PARAM);
  const {verifyRecaptchaChallenge} = useRecaptcha();

  const submitHandler = useCallback(
    async (data: PlayerContactDetailsFormSubmission) => {
      setIsLoading(true);
      if (!IS_TEST_ENVIRONMENT && process.env.REACT_APP_IS_CAPTCHA_DISABLED !== 'true') {
        try {
          const isRecaptchaVerified = await verifyRecaptchaChallenge();
          if (!isRecaptchaVerified) {
            setErrorMessage('reCAPTCHA challenge failed');
            setIsLoading(false);
            return;
          }
        } catch (err) {
          setErrorMessage(ERROR_MESSAGES.RECAPTCHA_FAILED);
          setIsLoading(false);
          return;
        }
      }
      const {firstName, lastName, email, phoneNumber, location} = data;
      const queryParams = new URLSearchParams(searchParams.toString());
      sendFkoHubspotData({
        tocaSource: queryParams.get('toca_source') ?? undefined,
        alternateProfileData: {
          firstName,
          lastName,
          phoneNumber,
          email,
        },
        locationId: location,
        isPlayerAssessment: false,
      });
      queryParams.set(HUBSPOT_USER_FIRST_NAME_PARAM, firstName);
      queryParams.set(HUBSPOT_USER_LAST_NAME_PARAM, lastName);
      queryParams.set(HUBSPOT_USER_EMAIL_PARAM, email);
      queryParams.set(HUBSPOT_USER_PHONE_PARAM, phoneNumber);
      queryParams.set(
        HUBSPOT_LOCATION_PARAM,
        locationHubspotName || getLocationBySiteId(location || '')?.hubspotName || ''
      );
      // Navigate with updated query parameters
      navigate(`/toca-programs?${queryParams.toString()}`);
      setIsLoading(false);
    },
    [navigate, searchParams]
  );

  const formDefaultValues = {
    firstName: searchParams.get(HUBSPOT_USER_FIRST_NAME_PARAM) || '',
    lastName: searchParams.get(HUBSPOT_USER_LAST_NAME_PARAM) || '',
    email: searchParams.get(HUBSPOT_USER_EMAIL_PARAM) || '',
    phoneNumber: searchParams.get(HUBSPOT_USER_PHONE_PARAM) || '',
  };

  const presetLocation = locationHubspotName ? getLocationByHubspotName(locationHubspotName, locations) : undefined;

  return (
    <div className="flex flex-col items-center exceed-classic-padding pl-6 pr-6">
      <Helmet>
        <title>TOCA | Player Contact Details</title>
      </Helmet>
      <TocaLogo />
      <h1 className="text-[26px] text-center mt-2 mb-4">Welcome to TOCA!</h1>
      <p className="text-base text-center mb-6 font-semibold">Please fill your contact details below.</p>
      <PlayerContactDetailsForm
        onSubmitHandler={submitHandler}
        presetLocation={presetLocation}
        defaultValues={formDefaultValues}
        isLoading={isLoading}
        errorMessage={errorMessage}
      />
      <div className="flex justify-center my-[20px]">
        <p className="text-[10px] text-[#aaa] text-center">
          This site is protected by reCAPTCHA and the Google{' '}
          <a
            className="underline text-[#999]"
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>{' '}
          and{' '}
          <a
            className="underline text-[#999]"
            href="https://policies.google.com/terms"
            target="_blank"
            rel="noreferrer"
          >
            Terms of Service
          </a>{' '}
          apply.
        </p>
      </div>
    </div>
  );
};

PlayerContactDetails.displayName = 'PlayerContactDetails';

export default PlayerContactDetails;
