import {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {getLocationBySiteId} from 'constants/locations';
import {useGetSingleProgram} from '../../api/Classes/useGetSingleProgram';
import {useGetSingleClassSchedule} from 'api/Classes/useGetSingleClassSchedule';
import {ClassSchedule, Enrollment, EnrollmentClass} from 'api/Classes/useGetPrograms';
import {Helmet} from 'react-helmet';
import {LoadingAnimationFullHeight} from 'components/Loader/LoadingAnimation';
import {PageHeading} from 'components/Connects/PageHeading';
import {MenuBar} from 'components/MenuBar/MenuBar';
import {Button} from 'components/Connects/Button';
import {Breadcrumbs} from 'components/Breadcrumbs/Breadcrumbs';
import {ReactComponent as LocationPinIcon} from '../../assets/img/icons/redesign-icons/location-pin.svg';
import {ReactComponent as SoccerballIcon} from '../../assets/img/icons/redesign-icons/soccerball-basic.svg';
import {ReactComponent as WhistleIcon} from '../../assets/img/icons/redesign-icons/whistle.svg';
import classPlaceholder from '../../assets/img/groupClass.jpg';
import {
  combineClassAndClassScheduleIds,
  format24hrTimeString,
  getClassDisplayName,
  parseClassAndClassScheduleIds,
  parseFormattedClassScheduleGroups,
} from 'utils/programs-utils';
import {Modal} from 'components/Modal/Modal';
import {ProgramEmptyDayForm} from 'components/Programs/ProgramEmptyDayForm';
import {selectOverrideImage} from 'components/Programs/EnrollmentCard';
import {useAppSelector} from 'redux/reduxUtils';
import {useGetSport} from 'common/useGetSport';
import moment from 'moment';
import {RadioWrapper} from 'components/RadioButton/RadioWrapper';

export const ProgramClassScheduleDetails = () => {
  const navigate = useNavigate();
  const {siteId, programId, enrollmentId, classId: presetClassId} = useParams();
  const [contactFormData, setContactFormData] = useState<Enrollment | ClassSchedule | null>();

  const {data: program} = useGetSingleProgram({siteId, programId});
  const {data: classSchedule, isLoading: isLoadingClassSchedule} = useGetSingleClassSchedule({
    siteId,
    programId,
    classScheduleId: enrollmentId, // TODO CLASSES naming
  });
  const {userData} = useAppSelector((state) => state.fkoFormDataReducer);
  const {basePath} = useGetSport();

  const openContactForm = useCallback(
    ({enrollment}: {enrollment: Enrollment | ClassSchedule | null}) => setContactFormData(enrollment),
    []
  );

  const enrollmentImage = selectOverrideImage({enrollmentName: classSchedule?.name});
  const locationName = getLocationBySiteId(siteId ?? '')?.name;
  const pageHeading = getClassDisplayName(classSchedule?.name);
  // const bookingUrl = 'register'; //  `/ftc/${siteId}/${programId}/${enrollmentId}/register`;

  useEffect(() => {
    if (!userData) {
      navigate(basePath, {replace: true});
    }
  }, [basePath, navigate, userData]);

  // TODO extract this mess to a component (here and `ClassScheduleGroupCard`)
  const {classScheduleGroups} = parseFormattedClassScheduleGroups({classSchedules: program?.classSchedules});
  const classScheduleGroup = classScheduleGroups?.find((group) =>
    group.classSchedules.find((cs) => cs.classScheduleId === enrollmentId)
  );
  const classSchedules = classScheduleGroup?.classSchedules;

  const [selectedCombinedId, setSelectedCombinedId] = useState<string>();

  const {classScheduleId: selectedClassScheduleId, classId} = parseClassAndClassScheduleIds({
    combinedIds: selectedCombinedId,
  });
  const selectedClassSchedule = classSchedules?.find((cs) => cs.classScheduleId === selectedClassScheduleId);

  const selectClass: React.FormEventHandler<HTMLInputElement> = useCallback(
    (e) => setSelectedCombinedId(e.currentTarget.value),
    [setSelectedCombinedId]
  );

  const availableClasses = useMemo(() => {
    const _clsArray: Array<EnrollmentClass & {combinedId: string}> = [];

    classSchedules?.forEach((cs) =>
      cs.classes?.forEach((cls) => {
        const combinedId = combineClassAndClassScheduleIds({
          classScheduleId: cs.classScheduleId,
          classId: cls.classId,
        });
        if (combinedId && cls.isAvailable && cls.capacity.available > 0) {
          _clsArray.push({...cls, combinedId});
        }
      })
    );

    return _clsArray.sort((a, b) => (moment(a.startDateTime).isAfter(moment(b.startDateTime)) ? 1 : -1));
  }, [classSchedules]);

  useEffect(() => {
    if (availableClasses?.length && !selectedCombinedId) {
      const initialCombinedId =
        combineClassAndClassScheduleIds({
          classScheduleId: enrollmentId,
          classId: presetClassId,
        }) ?? availableClasses[0].combinedId;
      setSelectedCombinedId(initialCombinedId);
    }
  }, [availableClasses, presetClassId, setSelectedCombinedId]);

  const bookingUrl = `${basePath}/${siteId}/${programId}/${selectedClassScheduleId}/${classId}/register`;

  return (
    <>
      <Helmet>
        <title>TOCA Soccer | Enrollment Details</title>
      </Helmet>

      <Modal isOpen={!!contactFormData} onClose={() => setContactFormData(null)} showHeader>
        <ProgramEmptyDayForm enrollment={contactFormData} />
      </Modal>

      <div className="grow flex flex-col gap-3 sm:gap-6">
        <MenuBar />
        {/* TODO CLASSES enrollment/classSchedule naming*/}
        <Breadcrumbs data={{program, enrollment: classSchedule}} />

        {isLoadingClassSchedule ? (
          <LoadingAnimationFullHeight />
        ) : classSchedule ? (
          <>
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-[3fr_2fr] md:grid-cols-2 lg:mx-auto max-w-[1024px]">
              <div className="text-center md:text-left sm:col-span-2">
                <PageHeading text={pageHeading} />
              </div>

              <div className="flex flex-col gap-4">
                {/* TODO CLASSES non-free trial classes prob need pricing */}
                {/* <p className="font-poppins font-semibold text-xl leading-[24px] text-secondary text-center sm:text-left">
                  {classSchedule.costPerSession && `$${classSchedule.costPerSession?.toFixed(2)}`}
                </p> */}

                <p className="font-poppins text-base text-blue-dark">{classSchedule.slug}</p>
                <p className="font-poppins text-base text-blue-dark">{classSchedule.description}</p>

                {classSchedule.highlights?.length || classSchedule.highlightTitle ? (
                  <div className="border border-grey-light rounded px-5 py-3 sm:self-start">
                    <p className="font-poppins text-base text-blue-dark mb-1">
                      {classSchedule.highlightTitle ?? (classSchedule.highlights?.length ? 'Highlights:' : undefined)}
                    </p>
                    <ul className="flex flex-col gap-1 list-disc list-inside">
                      {classSchedule.highlights?.map((highlight, i) => (
                        <li className="font-poppins text-sm text-blue-dark" key={`highlight${i}`}>
                          {highlight}
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}

                <TagCards enrollment={classSchedule} locationName={locationName} />

                {availableClasses.length ? (
                  <div className="flex flex-col gap-1 mt-4">
                    <p className="font-poppins font-semibold text-sm text-grey-xdark">Pick a day and time</p>
                    {availableClasses.map((cls) => (
                      <RadioWrapper
                        key={cls.combinedId}
                        id={cls.combinedId}
                        value={cls.combinedId}
                        checked={selectedCombinedId === cls.combinedId}
                        onChange={selectClass}
                      >
                        <p className="font-poppins font-medium text-sm text-grey-xdark">
                          {moment(cls.startDateString ?? cls.startDateTime).format('ddd, MMM D')}
                          {` at `}
                          {format24hrTimeString({time: cls.startTimeString})}
                        </p>
                      </RadioWrapper>
                    ))}
                  </div>
                ) : null}

                <div className="flex flex-col gap-x-6 gap-y-2 md:my-2 md:flex-row md:items-center flex-wrap">
                  {/* {classSchedule.capacity.available ? ( */}
                  {selectedClassSchedule?.availableCount ? (
                    <Link to={bookingUrl}>
                      <Button text="Book&nbsp;Now" variant="cta" color="primary" />
                    </Link>
                  ) : (
                    <div>
                      <Button text="Book&nbsp;Now" variant="cta" color="grey" disabled />
                    </div>
                  )}

                  {/* ---disabling this as the display is confusing for Classes--- */}
                  {/* TODO CLASSES: re-evaluate when Enrollments are a thing again */}
                  {/* <p className="font-poppins text-sm text-grey-xdark text-center">
                    {classSchedule.capacity.available} {classSchedule.capacity.available === 1 ? 'spot' : 'spots'}{' '}
                    available
                  </p> */}

                  {!classSchedule.capacity.available && (
                    <div className="w-full grid place-content-center">
                      <button
                        onClick={() => openContactForm({enrollment: classSchedule})}
                        className="text-sm text-primary font-medium underline"
                      >
                        Click here to get notified of any availabilities.
                      </button>
                    </div>
                  )}
                </div>
              </div>

              <div className="rounded-md">
                <img
                  src={enrollmentImage ?? classPlaceholder}
                  alt=""
                  className="rounded-lg aspect-[4/3] sm:aspect-square lg:aspect-[4/3] object-cover object-[25%,60%]"
                />
              </div>
            </div>
          </>
        ) : (
          <div>
            {/* TODO CLASSES make better */}
            <p>Unable to load enrollment data</p>
          </div>
        )}
      </div>
    </>
  );
};

type TagCardsProps = {enrollment: Enrollment | ClassSchedule; locationName?: string};
export const TagCards = memo(({enrollment, locationName}: TagCardsProps) => {
  const formattedStartTime = format24hrTimeString({time: enrollment.schedule.startTime, includeTimePeriod: false});
  const formattedEndTime = format24hrTimeString({time: enrollment.schedule.endTime});

  return (
    <div className="grid grid-cols-2 gap-3">
      {/* <div className="flex items-center gap-2 p-4 bg-grey-xlight rounded-md">
        <ClockFaceIcon className="h-4 w-4 min-h-4 fill-grey-xdark" />
        <p className="font-poppins font-medium text-sm text-grey-xdark capitalize">
          {formattedStartTime}&nbsp;-&nbsp;{formattedEndTime}
        </p>
      </div>
      <div className="flex items-center gap-2 p-4 bg-grey-xlight rounded-md">
        <CalendarIcon className="h-4 w-4 min-h-4 fill-grey-xdark" />
        <p className="font-poppins font-medium text-sm text-grey-xdark capitalize">
          {formatWeekdaysArrayForDisplay(enrollment.schedule.daysArray)}
        </p>
      </div> */}
      {locationName && (
        <div className="flex items-center gap-2 p-4 bg-grey-xlight rounded-md">
          <LocationPinIcon className="h-4 w-4 min-h-4 fill-grey-xdark" />
          <p className="font-poppins font-medium text-sm text-grey-xdark capitalize">{locationName}</p>
        </div>
      )}
      {enrollment.skillLevel && (
        <div className="flex items-center gap-2 p-4 bg-grey-xlight rounded-md">
          <WhistleIcon className="h-4 w-4 min-h-4 fill-grey-xdark" />
          <p className="font-poppins font-medium text-sm text-grey-xdark capitalize">
            Skill&nbsp;Level:&nbsp;{enrollment.skillLevel}
          </p>
        </div>
      )}
      {enrollment.tags?.classType && (
        <div className="flex items-center gap-2 p-4 bg-grey-xlight rounded-md">
          <SoccerballIcon className="h-4 w-4 min-h-4 fill-grey-xdark" />
          <p className="font-poppins font-medium text-sm text-grey-xdark capitalize">{enrollment.tags.classType}</p>
        </div>
      )}
      {/* <div className="flex items-center gap-2 p-4 bg-grey-xlight rounded-md">
        <SunshineIcon className="h-4 w-4 min-h-4 fill-grey-xdark" />
        <p className="font-poppins font-medium text-sm text-grey-xdark capitalize">Summer</p>
      </div> */}
      {/* <div className="flex items-center gap-2 p-4 bg-grey-xlight rounded-md">
        <ClockFaceIcon className="h-4 w-4 min-h-4 fill-grey-xdark" />
        <p className="font-poppins font-medium text-sm text-grey-xdark capitalize">Evenings</p>
      </div> */}
    </div>
  );
});
TagCards.displayName = 'TagCards';
