import {memo} from 'react';
import {Helmet} from 'react-helmet';
import styles from '.././SignInSignUp.module.scss';
import {Link} from 'react-router-dom';
import {ReactComponent as MyTocaLogo} from '../../../assets/img/icons/redesign-icons/mytoca-logo.svg';
import {signInWithCredential, PhoneAuthProvider} from 'firebase/auth';
import {auth as fireBaseAuth} from '../../../firebase/config';
import {BasePhoneVerification} from './BasePhoneVerification';
import {setAccessToken, setCurrentAccountId, getPostLoginRedirectUri} from '../../../user/user-utils';
import {useNavigate} from 'react-router-dom';
import {logLoginEvent} from 'common/analytics-events';
import {StandardButton} from '../../../components/Buttons/Standard/StandardButton';
import {Navigate} from 'react-router-dom';

export const PhoneSignIn = memo(() => {
  const navigate = useNavigate();

  const handleVerificationComplete = async (phoneNumber: string, verificationId: string, verificationCode: string) => {
    const credential = PhoneAuthProvider.credential(verificationId, verificationCode);
    const userCredential: any = await signInWithCredential(fireBaseAuth, credential);

    const {user} = userCredential;
    setAccessToken(user.accessToken);
    setCurrentAccountId(user.uid);

    logLoginEvent('phoneNumber', {
      userUid: user.uid,
      operationType: userCredential.operationType,
    });
    navigate(getPostLoginRedirectUri());
  };

  if (process.env.REACT_APP_PHONE_ADD_SIGN_IN_ENABLED == 'false') {
    return <Navigate to="/auth" />;
  }

  return (
    <>
      <Helmet>
        <title>MyTOCA | Sign In/Sign Up</title>
      </Helmet>

      <div className={styles.container}>
        <div className={styles.logo}>
          <Link to={'/auth'}>
            <MyTocaLogo />
          </Link>
        </div>
        <h1 className={styles.heading}>Welcome</h1>
        <h2 className={styles.subheading}>Please Log in with your MyTOCA credentials</h2>
      </div>
      <BasePhoneVerification onVerificationComplete={handleVerificationComplete} buttonText="Send Verification Code" />

      <StandardButton onClick={() => navigate('/auth')} text="Back to Sign In" variant="light" customClass="mt-8" />
    </>
  );
});

PhoneSignIn.displayName = 'PhoneSignIn';
