import {useQuery} from '@tanstack/react-query';
import {getClubPublicInfo} from 'api/api';
import {Club} from 'api/Director/club.interface';

export const useGetClubPublicInfo = ({clubId}: {clubId?: string}) => {
  return useQuery<Partial<Club>>({
    queryKey: ['club-public', clubId],
    queryFn: () => getClubPublicInfo({clubId}),
    enabled: !!clubId,
  });
};
