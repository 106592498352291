import {useSportContext} from 'context/SportContext';
import {
  dbatPermittedLocationIds,
  fkoPermittedLocationIds,
  freeTrialClassesPermittedLocationIds,
  playerAssessmentPermittedLocationIds,
  dbat75SwingsPermittedLocationIds,
  ftcDsrPermittedLocationIds,
  freeTrialPickupSoccerPermittedLocationIds,
  freeTrialLeaguesPermittedLocationIds,
} from 'feature-flags/fko-locations';
import {useMemo} from 'react';
import {useParams} from 'react-router-dom';

export type SportName =
  | 'dbat'
  | 'playerAssessment'
  | 'classes'
  | 'freeTrialClasses'
  | 'toca'
  | 'dbat75Swings'
  | 'ftcDsr'
  | 'freeTrialPickupSoccer'
  | 'freeTrialLeagues';

export interface SportInfo {
  sport: SportName;
  sessionTypeName: string;
  isDbat: boolean;
  isDbat75Swings: boolean;
  isFtcDsr: boolean;
  isPlayerAssessment: boolean;
  isClasses: boolean;
  isFreeTrialClasses: boolean;
  isFreeTrialPickupSoccer: boolean;
  isFreeTrialLeagues: boolean;
  isFreeBaselineSession: boolean;
  basePath: string;
  basePathWithoutSessionId: string;
  fkoMethod: string; // Needs to match expected HubSpot value
  fkoAnalyticsPrefix: string;
  sportPermittedLocations: string[];
}

export const useGetSport = (): SportInfo => {
  const {sport} = useSportContext();
  const {sessionId} = useParams();

  const sportInfo: SportInfo = useMemo(() => {
    if (sport === 'DBAT_75_SWINGS') {
      return {
        sport: 'dbat75Swings',
        sessionTypeName: 'DBAT 75 Free Swings',
        isDbat75Swings: true,
        isFreeBaselineSession: false,
        isDbat: false,
        isFtcDsr: false,
        isPlayerAssessment: false,
        isClasses: false,
        isFreeTrialClasses: false,
        isFreeTrialPickupSoccer: false,
        isFreeTrialLeagues: false,
        basePath: 'offer/dbat-75-free-swings-claim',
        basePathWithoutSessionId: 'offer/dbat-75-free-swings-claim',
        fkoMethod: 'player_portal_dbat_75_swings',
        fkoAnalyticsPrefix: 'dbat_75_swings',
        sportPermittedLocations: dbat75SwingsPermittedLocationIds,
      };
    }

    if (sport === 'FTC_DSR') {
      return {
        sport: 'ftcDsr',
        sessionTypeName: 'Free Trial Class',
        isDbat75Swings: false,
        isFtcDsr: true,
        isFreeBaselineSession: false,
        isDbat: false,
        isPlayerAssessment: false,
        isClasses: false,
        isFreeTrialClasses: false,
        isFreeTrialPickupSoccer: false,
        isFreeTrialLeagues: false,
        basePath: '/ftc-dsr',
        basePathWithoutSessionId: '/ftc-dsr',
        fkoMethod: 'player_portal_ftc_dsr',
        fkoAnalyticsPrefix: 'ftc_dsr',
        sportPermittedLocations: ftcDsrPermittedLocationIds,
      };
    }

    if (sport === 'FREE_TRIAL_PICKUP_SOCCER') {
      return {
        sport: 'freeTrialPickupSoccer',
        sessionTypeName: 'Free Trial Class',
        isDbat75Swings: false,
        isFtcDsr: false,
        isFreeBaselineSession: false,
        isDbat: false,
        isPlayerAssessment: false,
        isClasses: false,
        isFreeTrialClasses: false,
        isFreeTrialPickupSoccer: true,
        isFreeTrialLeagues: false,
        basePath: '/ftp',
        basePathWithoutSessionId: '/ftp',
        fkoMethod: 'player_portal_free_trial_pickup_soccer_registration',
        fkoAnalyticsPrefix: 'free_trial_pickup_soccer',
        sportPermittedLocations: freeTrialPickupSoccerPermittedLocationIds,
      };
    }

    if (sport === 'FREE_TRIAL_LEAGUES') {
      return {
        sport: 'freeTrialLeagues',
        sessionTypeName: 'Free Trial Class',
        isDbat75Swings: false,
        isFtcDsr: false,
        isFreeBaselineSession: false,
        isDbat: false,
        isPlayerAssessment: false,
        isClasses: false,
        isFreeTrialClasses: false,
        isFreeTrialPickupSoccer: false,
        isFreeTrialLeagues: true,
        basePath: '/ftl',
        basePathWithoutSessionId: '/ftl',
        fkoMethod: 'player_portal_free_toca_class_registration',
        fkoAnalyticsPrefix: 'free_trial_classes',
        sportPermittedLocations: freeTrialLeaguesPermittedLocationIds,
      };
    }

    if (sport === 'DBAT') {
      return {
        sport: 'dbat',
        sessionTypeName: 'Free Evaluation',
        isDbat75Swings: false,
        isFtcDsr: false,
        isFreeBaselineSession: false,
        isDbat: true,
        isPlayerAssessment: false,
        isClasses: false,
        isFreeTrialClasses: false,
        isFreeTrialPickupSoccer: false,
        isFreeTrialLeagues: false,
        basePath: sessionId ? `/${sessionId}/dbat-fps` : '/dbat-fps',
        basePathWithoutSessionId: '/dbat-fps',
        fkoMethod: 'player_portal_d-bat_promotional_lesson_registration',
        fkoAnalyticsPrefix: 'dbat_fko',
        sportPermittedLocations: dbatPermittedLocationIds,
      };
    }

    if (sport === 'PLAYER_ASSESSMENT') {
      return {
        sport: 'playerAssessment',
        sessionTypeName: 'Player Assessment',
        isDbat75Swings: false,
        isFtcDsr: false,
        isFreeBaselineSession: false,
        isDbat: false,
        isPlayerAssessment: true,
        isClasses: false,
        isFreeTrialClasses: false,
        isFreeTrialPickupSoccer: false,
        isFreeTrialLeagues: false,
        basePath: sessionId ? `/${sessionId}/player-assessment` : '/player-assessment',
        basePathWithoutSessionId: '/player-assessment',
        fkoMethod: 'player_portal_player_assessment_registration',
        fkoAnalyticsPrefix: 'player_assessment_fko',
        sportPermittedLocations: playerAssessmentPermittedLocationIds,
      };
    }

    if (sport === 'FREE_TRIAL_CLASS') {
      return {
        sport: 'freeTrialClasses',
        sessionTypeName: 'Free Trial Class',
        isDbat75Swings: false,
        isFtcDsr: false,
        isFreeBaselineSession: false,
        isDbat: false,
        isPlayerAssessment: false,
        isClasses: false,
        isFreeTrialClasses: true,
        isFreeTrialPickupSoccer: false,
        isFreeTrialLeagues: false,
        basePath: sessionId ? `/${sessionId}/ftc` : '/ftc',
        basePathWithoutSessionId: '/ftc',
        fkoMethod: 'player_portal_free_toca_class_registration',
        fkoAnalyticsPrefix: 'free_trial_classes',
        sportPermittedLocations: freeTrialClassesPermittedLocationIds,
      };
    }

    // TODO CLASSES we probably need to support both free trial and regular classes at some point
    // if (sport === 'CLASSES') {
    //   return {
    //     sport: 'classes',
    //     sessionTypeName: 'Free Trial Class', // TODO
    //     isDbat: false,
    //     isPlayerAssessment: false,
    //     isClasses: true,
    //     isFreeTrialClasses: false,
    //     basePath: sessionId ? `/${sessionId}/classes` : '/classes',
    //     basePathWithoutSessionId: '/classes',
    //     fkoMethod: 'player_portal_free_toca_class_registration', // TODO
    //     fkoAnalyticsPrefix: 'free_trial_classes', // TODO
    //     sportPermittedLocations: freeTrialClassesPermittedLocationIds, // TODO
    //   };
    // }

    return {
      sport: 'toca',
      sessionTypeName: 'Free Baseline Session',
      isDbat75Swings: false,
      isFtcDsr: false,
      isFreeBaselineSession: true,
      isDbat: false,
      isPlayerAssessment: false,
      isClasses: false,
      isFreeTrialClasses: false,
      isFreeTrialPickupSoccer: false,
      isFreeTrialLeagues: false,
      basePath: sessionId ? `/${sessionId}/fko` : '/fko',
      basePathWithoutSessionId: '/fko',
      fkoMethod: 'player_portal_fko_registration',
      fkoAnalyticsPrefix: 'fko',
      sportPermittedLocations: fkoPermittedLocationIds,
    };
  }, [sessionId, sport]);

  return sportInfo;
};
