import {tocaLocations} from 'constants/locations';
import {isDevelopmentEnvironment} from '../common/isTestEnvironment';
import {tocaLocationsWithoutWorkingMboSiteId} from 'constants/locations';

// TODO eval need for this
export const locationLockEnabled = process.env.REACT_APP_LOCATION_LOCK_ENABLED === 'true';

export const fkoPermittedLocationIds = tocaLocations
  .filter((loc) => !!loc.fkoSupported && (!isDevelopmentEnvironment || loc.devSite))
  .map((loc) => loc.siteId);
export const dbatPermittedLocationIds = tocaLocations
  .filter((loc) => !!loc.dbatSupported && (!isDevelopmentEnvironment || loc.devSite))
  .map((loc) => loc.siteId);
export const playerAssessmentPermittedLocationIds = tocaLocations
  .filter((loc) => !!loc.playerAssessmentSupported && (!isDevelopmentEnvironment || loc.devSite))
  .map((loc) => loc.siteId);
export const classesPermittedLocationIds = tocaLocations
  .filter((loc) => !!loc.classesSupported && (!isDevelopmentEnvironment || loc.devSite))
  .map((loc) => loc.siteId);
export const freeTrialClassesPermittedLocationIds = tocaLocations
  .filter((loc) => !!loc.classesFreeTrialEnabled && (!isDevelopmentEnvironment || loc.devSite))
  .map((loc) => loc.siteId);
export const dbat75SwingsPermittedLocationIds = tocaLocations
  .filter((loc) => !!loc.dbat75SwingsSupported)
  .map((loc) => loc.siteId);
export const ftcDsrPermittedLocationIds = [...tocaLocations, ...tocaLocationsWithoutWorkingMboSiteId]
  .filter((loc) => loc.ftcDsrSupported)
  .map((loc) => loc.siteId);
export const freeTrialPickupSoccerPermittedLocationIds = [...tocaLocations, ...tocaLocationsWithoutWorkingMboSiteId]
  .filter((loc) => loc.pickupSoccerSupported)
  .map((loc) => loc.siteId);
export const freeTrialLeaguesPermittedLocationIds = [...tocaLocations, ...tocaLocationsWithoutWorkingMboSiteId]
  .filter((loc) => loc.leaguesSupported)
  .map((loc) => loc.siteId);

/**
 * Determines whether the given location is permitted for FKO.
 */
export const isLocationPermitted = ({
  locationId,
  isDbat = false,
  isPlayerAssessment = false,
  isClasses = false,
  isFreeTrialClasses = false,
  isDbat75Swings = false,
  isFtcDsr = false,
  isFreeTrialPickupSoccer = false,
  isFreeTrialLeagues = false,
}: {
  locationId: string;
  isDbat?: boolean;
  isPlayerAssessment?: boolean;
  isClasses?: boolean;
  isFreeTrialClasses?: boolean;
  isDbat75Swings?: boolean;
  isFtcDsr?: boolean;
  isFreeTrialPickupSoccer?: boolean;
  isFreeTrialLeagues?: boolean;
}) => {
  const permittedIds = isDbat
    ? dbatPermittedLocationIds
    : isPlayerAssessment
    ? playerAssessmentPermittedLocationIds
    : isClasses
    ? classesPermittedLocationIds
    : isFreeTrialClasses
    ? freeTrialClassesPermittedLocationIds
    : isDbat75Swings
    ? dbat75SwingsPermittedLocationIds
    : isFtcDsr
    ? ftcDsrPermittedLocationIds
    : isFreeTrialPickupSoccer
    ? freeTrialPickupSoccerPermittedLocationIds
    : isFreeTrialLeagues
    ? freeTrialLeaguesPermittedLocationIds
    : fkoPermittedLocationIds;

  return permittedIds?.includes(locationId);
};
