import {useMemo} from 'react';
import {useAppSelector} from 'redux/reduxUtils';
import {useGetSport} from 'common/useGetSport';
import {ProgressStepper} from 'components/ProgressStepper/ProgressStepper';
import {FKOPages} from 'common/fko-utils';

const STEPS = [
  {title: 'Contact Info', page: FKOPages.CONTACT_INFO},
  {title: 'Player Info', page: FKOPages.PLAYERS},
  {title: 'Select Time', page: FKOPages.SCHEDULE},
  {title: 'Complete', page: FKOPages.CONFIRMATION},
];

export const FKOProgressStepper = () => {
  const {currentPage, playersArray, userData} = useAppSelector((state) => state.fkoFormDataReducer);
  const {sport} = useGetSport();

  const completedSteps: number[] = useMemo(() => {
    if (!userData) {
      return [];
    }
    const eligiblePlayersCount = playersArray.filter((player) => player.eligibility?.isEligible).length;
    const scheduledAppointmentsCount = playersArray.filter((player) => player.selectedSession).length;
    const confirmedAppointmentsCount = playersArray.filter(
      (player) => player.selectedSession && player.appointmentConfirmed
    ).length;

    const steps: number[] = [0];

    if (eligiblePlayersCount > 0) {
      steps.push(1);
    }
    if (scheduledAppointmentsCount > 0) {
      steps.push(2);
    }
    if (confirmedAppointmentsCount > 0) {
      steps.push(3);
    }

    return steps;
  }, [currentPage, playersArray, userData]);

  const currentStepIndex = useMemo(() => STEPS.findIndex((step) => step.page === currentPage), [currentPage]);

  const showStepper = useMemo(() => {
    return (sport === 'toca' || sport === 'dbat') && currentStepIndex > 0;
  }, [currentStepIndex, sport]);

  if (!showStepper) {
    return null;
  }

  return (
    <div className="py-4 px-10 pb-10 w-full">
      <ProgressStepper steps={STEPS} currentStep={currentStepIndex} completedSteps={completedSteps} />
    </div>
  );
};
