import {ReactComponent as LastStepIcon} from '../../assets/img/icons/redesign-icons/flag-icon.svg';

interface ProgressStepperProps {
  steps: {title: string}[];
  currentStep: number;
  completedSteps?: number[];
}

interface StepCircleProps {
  isStepCompleted: boolean;
  isActiveStep: boolean;
  index: number;
  isLastStep: boolean;
}

const StepCircle = ({isStepCompleted, isActiveStep, index, isLastStep}: StepCircleProps) => (
  <div
    className={`flex items-center justify-center w-10 h-10 rounded-full text-sm font-bold 
    ${
      isStepCompleted
        ? 'bg-primary text-white'
        : isActiveStep
        ? 'border-2 border-primary text-primary bg-white'
        : 'bg-grey-light text-grey-light'
    }`}
  >
    {isStepCompleted ? (
      <span>&#10003;</span> // Checkmark
    ) : !isLastStep ? (
      <span className="text-primary">{index + 1}</span> // Step number
    ) : (
      <LastStepIcon className="ml-1" />
    )}
  </div>
);

interface ProgressStepProps {
  step: {title: string};
  index: number;
  isLastStep: boolean;
  isStepCompleted: boolean;
  isActiveStep: boolean;
}

const ProgressStep = ({step, index, isLastStep, isStepCompleted, isActiveStep}: ProgressStepProps) => (
  <div className={`flex items-center ${!isLastStep ? 'w-full' : ''}`}>
    <div className="flex flex-col items-center relative">
      <StepCircle isStepCompleted={isStepCompleted} isActiveStep={isActiveStep} index={index} isLastStep={isLastStep} />
      <div className="absolute top-12 text-xs text-center whitespace-nowrap">{step.title}</div>
    </div>
    {/* Divider */}
    {!isLastStep && <div className={`h-0.5 flex-1 ${isStepCompleted ? 'bg-primary' : 'bg-grey'}`}></div>}
  </div>
);

export const ProgressStepper = ({steps, currentStep, completedSteps = []}: ProgressStepperProps) => {
  return (
    <div className="flex items-center justify-between w-full">
      {steps.map((step, index) => {
        const isLastStep = index === steps.length - 1;
        const isStepCompleted = completedSteps.includes(index);
        const isActiveStep = currentStep === index;
        return (
          <ProgressStep
            key={index}
            step={step}
            index={index}
            isLastStep={isLastStep}
            isStepCompleted={isStepCompleted}
            isActiveStep={isActiveStep}
          />
        );
      })}
    </div>
  );
};

ProgressStepper.displayName = 'ProgressStepper';
