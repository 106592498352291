import {useCallback, useState} from 'react';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {toast} from 'react-toastify';
import {addTeamToClub} from 'api/api';
import {ClubTeamForm, ClubTeamFormData} from './ClubTeamForm';
import {Modal} from 'components/Modal/Modal';
import {useProfiles} from 'api/User/useProfiles';
import {useGetDirectorInfo} from 'api/Director/useGetDirectorInfo';

interface AddTeamToClubModalProps {
  showModal: boolean;
  onClose: () => void;
  clubId: string;
}

export interface AssignDirectorData {
  email: string;
  teamId: string;
}

export const AddTeamToClubModal = ({showModal, onClose, clubId}: AddTeamToClubModalProps): JSX.Element => {
  const [errorMsg, setErrorMsg] = useState('');
  const queryClient = useQueryClient();
  const {currentProfile} = useProfiles();
  const {data: directorInfo} = useGetDirectorInfo(currentProfile?._id);
  const siteId = directorInfo?.club?.siteId || directorInfo?.teams[0]?.siteId;

  const invalidateQueries = useCallback(() => {
    queryClient.invalidateQueries(['director-info']);
  }, [queryClient]);

  const createTeamMutation = useMutation({
    mutationFn: (data: ClubTeamFormData & {siteId: string}) => addTeamToClub(data, clubId),
  });

  const handleError = (error: any) => {
    const errMessage = error?.response?.data?.message || error?.response?.data?.error || 'Failed to create team.';
    setErrorMsg(errMessage);
  };

  const createTeamHandler = useCallback(
    async (data: ClubTeamFormData) => {
      try {
        if (!siteId) {
          setErrorMsg('Site ID is required');
          return;
        }
        // Create the team with clubId
        const {team, club} = await createTeamMutation.mutateAsync({...data, siteId});

        invalidateQueries();
        onClose();
        toast.success('Successfully created team.');
      } catch (error: any) {
        handleError(error);
        if (createTeamMutation.isSuccess) {
          invalidateQueries();
        }
      }
    },
    [createTeamMutation, clubId, currentProfile, onClose]
  );

  return (
    <Modal
      isOpen={showModal}
      onClose={onClose}
      showHeader={true}
      title="Add Team to Club"
      modalTestId="add-team-to-club-modal"
    >
      <ClubTeamForm
        onCancelHandler={onClose}
        onSubmitHandler={createTeamHandler}
        loading={createTeamMutation.isLoading}
        error={errorMsg}
      />
    </Modal>
  );
};
