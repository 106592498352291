import {useEffect, useState} from 'react';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useNavigate, useParams} from 'react-router-dom';
import {useProfiles} from 'api/User/useProfiles';
import {useForm} from 'react-hook-form';
import {useQueryClient} from '@tanstack/react-query';
import {toast} from 'react-toastify';
import {useUpdateTeam} from 'api/CoachAndDirector/useUpdateTeam';
import {useGetDirectorInfo} from 'api/Director/useGetDirectorInfo';
import {roleType} from 'constants/constants';
import {EditTeamView} from 'pages/Coach/CoachEditTeam/EditTeam.view';

type FormTypes = {
  teamName: string;
};

const validationSchema = yup.object().shape({
  teamName: yup.string().required('Team name is required'),
});

export const DirectorEditTeam = () => {
  const navigate = useNavigate();
  const {teamId} = useParams();
  const queryClient = useQueryClient();
  const [selectedColor, setSelectedColor] = useState<string>('');
  const [editTeamName, setEditTeamName] = useState<boolean>(false);
  const [showTeamColorSelectionModal, setShowTeamColorSelectionModal] = useState<boolean>(false);
  const {currentProfile} = useProfiles();
  const {data: directorInfo, isLoading} = useGetDirectorInfo(currentProfile?._id);
  const team = directorInfo?.teams.find((team) => team._id === teamId);

  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm<FormTypes>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const onTeamUpdateSuccessHandler = () => {
    queryClient.invalidateQueries({queryKey: ['director-info', directorInfo?.directorDetails._id]});
    toast.success('Team updated successfully.');
    setEditTeamName(false);
  };

  const onTeamUpdateErrorHandler = () => {
    toast.error('Failed to update team.');
  };

  const {updateTeam} = useUpdateTeam(onTeamUpdateSuccessHandler, onTeamUpdateErrorHandler, roleType.director);

  useEffect(() => {
    if (team?.teamColor) {
      setSelectedColor(team.teamColor);
    }
    if (team?.teamName) {
      setValue('teamName', team?.teamName);
    }
  }, [team, setValue]);

  const onClickCancelHandler = () => {
    navigate(`/director/teams/${teamId}`);
  };

  const onSubmitHandler = async (data: FormTypes) => {
    if (teamId) {
      await updateTeam({
        teamDetails: {teamName: data?.teamName?.trim(), teamColor: selectedColor, _id: teamId},
        teamId,
      });
      navigate(`/director/teams/${teamId}`);
    }
  };

  if (!team) {
    return <div>Team not found</div>;
  }

  return (
    <EditTeamView
      isLoading={isLoading}
      team={team}
      selectedColor={selectedColor}
      setSelectedColor={setSelectedColor}
      showTeamColorSelectionModal={showTeamColorSelectionModal}
      setShowTeamColorSelectionModal={setShowTeamColorSelectionModal}
      editTeamName={editTeamName}
      setEditTeamName={setEditTeamName}
      handleSubmit={handleSubmit}
      onSubmitHandler={onSubmitHandler}
      onClickCancelHandler={onClickCancelHandler}
      register={register}
      errors={errors}
    />
  );
};
