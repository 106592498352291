import {useGetSport} from 'common/useGetSport';
import styles from '../FkoConfirmationPage.module.scss';

const FkoSubHeading = () => {
  const {isFreeBaselineSession, isDbat} = useGetSport();

  let subHeading = (
    <>
      <p className={styles.subheading}>
        Please wear flat soccer or athletic shoes, as cleats are not allowed on the turf, as well as comfortable
        clothing. Oh, and don&apos;t forget water!
      </p>
      <p className="text-fko-primary text-base mt-4">We Look Forward to Meeting You!</p>
    </>
  );

  if (isFreeBaselineSession) {
    subHeading = (
      <>
        <p className="text-sm">We can’t wait to help you achieve your soccer dreams!</p>
        <p className="text-sm mt-4">
          As an Official Soccer Training Partner of MLS, our proprietary, tech-enhanced training has helped over 50,000
          soccer players build their skills and confidence on the pitch.
        </p>
        <p className="text-sm mt-4">
          Using data from your Baseline Session, we’ll create a personalized plan focused on YOU that provides 10
          matches worth of touches in every session!
        </p>
      </>
    );
  } else if (isDbat) {
    subHeading = (
      <>
        <p className={styles.subheading}>
          Please wear appropriate shoes, as well as comfortable clothing. Oh, and don&apos;t forget water!
        </p>
        <p className="text-fko-primary text-base mt-4">We Look Forward to Meeting You!</p>
      </>
    );
  }

  return subHeading;
};

export default FkoSubHeading;
